import React, { useLayoutEffect, useRef, useState, useEffect, Fragment } from "react"

import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import HeroSection from "../../components/hero-section"

import "./style.css"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import ReactDOM from 'react-dom';

import TimelineStep1 from "../../components/builder/builder-timeline_step1"
import TimelineStep2 from "../../components/builder/builder-timeline_step2"
import TimelineStep3 from "../../components/builder/builder-timeline_step3"
import TimelineStep4 from "../../components/builder/builder-timeline_step4"
import TimelineStep5 from "../../components/builder/builder-timeline_step5"
import TimelineStep6 from "../../components/builder/builder-timeline_step6"
import TimelineStep7 from "../../components/builder/builder-timeline_step7"


export default function HowItWorks() {

  //ADD WEB EVENT
  function addWebEvent(event_type, event_name) {
    var data = new FormData();
    data.append("event_type", event_type);
    data.append("event_name", event_name);

    const queryString = typeof window !== "undefined" ? window.location.search : ""
    const urlParams = new URLSearchParams(queryString);
    var cid = urlParams.get("cid");
    data.append("cid", cid);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.checksolarcost.com/v1/addWebEvent");
    xhr.send(data);
}
  useEffect(() => {
    addWebEvent("visit", "how-it-works")
  },[]);




    const [sliderRef, setSliderRef] = useState(null)
    const settings = {
      dots: false,
      //infinite: false,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      //centerMode: false,
      //fade: false,
      //autoplay: true,
      //initialSlide: 1,
      //lazyLoad: false,
      //autoplaySpeed: 1000,
      //pauseOnDotsHover: true,
      //swipeToSlide: true,
      swipe: false
      
    };

  //SET DEFAULT TIMELINE
  useEffect(() => {
    ReactDOM.render(<TimelineStep1/>, document.getElementById('timeline_area'));
  },[]);

  function selectAreaOnTimeline(stage) {

    if (stage === "1next") { 
      ReactDOM.render(<TimelineStep2/>, document.getElementById('timeline_area'));
    }
    if (stage === "2next") { 
      ReactDOM.render(<TimelineStep3/>, document.getElementById('timeline_area'));
    }
    if (stage === "3next") { 
      ReactDOM.render(<TimelineStep4/>, document.getElementById('timeline_area'));
    }
    if (stage === "4next") { 
      ReactDOM.render(<TimelineStep5/>, document.getElementById('timeline_area'));
    }
    if (stage === "5next") { 
      ReactDOM.render(<TimelineStep6/>, document.getElementById('timeline_area'));
    }
    if (stage === "6next") { 
      ReactDOM.render(<TimelineStep7/>, document.getElementById('timeline_area'));
    }
    if (stage === "7next") { 
      ReactDOM.render(<TimelineStep1/>, document.getElementById('timeline_area'));
    }

    if (stage === "1prev") { 
      ReactDOM.render(<TimelineStep7/>, document.getElementById('timeline_area'));
    }
    if (stage === "2prev") { 
      ReactDOM.render(<TimelineStep1/>, document.getElementById('timeline_area'));
    }
    if (stage === "3prev") { 
      ReactDOM.render(<TimelineStep2/>, document.getElementById('timeline_area'));
    }
    if (stage === "4prev") { 
      ReactDOM.render(<TimelineStep3/>, document.getElementById('timeline_area'));
    }
    if (stage === "5prev") { 
      ReactDOM.render(<TimelineStep4/>, document.getElementById('timeline_area'));
    }
    if (stage === "6prev") { 
      ReactDOM.render(<TimelineStep5/>, document.getElementById('timeline_area'));
    }
    if (stage === "7prev") { 
      ReactDOM.render(<TimelineStep6/>, document.getElementById('timeline_area'));
    }
  }

  return (

    <>
    <div >
    <Layout >
      
      <SEO title="How It Works" />

      <Slider ref={setSliderRef} {...settings } className="overflow-hidden" style={{"backgroundColor":"#323D37"}}>
  
        <section >
          <div style={{
              "backgroundImage": "linear-gradient( rgba(79, 83, 81, 0.9), rgba(79, 83, 81, 0.9) ), url('https://api.checksolarcost.com/v1/getImageFile/how_it_works_slide2.jpg')", 
              "backgroundSize": "cover",
              "backgroundPosition": "center",
              "backgroundRepeat": "no-repeat",
              "width": '100vw',
              "height": '100%',
              "filter": "blur(20%)"
              }}>
   
              <div className="howitworks-main-table">
              <table>
                <tbody style={{"padding":"0px", "verticalAlign": "top"}}>
                  <tr>
                    <td rowSpan="2" className="howitworks-side-big-arrows" style={{"verticalAlign":"middle", "width":"30px"}} onClick={sliderRef?.slickPrev}>
                      <div className="text-gray-400 font-light font-maxeville" onClick={()=> {selectAreaOnTimeline("1prev");}}>
                          <img src="https://api.checksolarcost.com/v1/getImageFile/desktop-navigation-left-big.png" width="35px"/> 
                      </div>
                    </td>
                    <td className="text-right">
                        <div className="text-gray-400 font-maxeville text-center howitworks-number">
                       1
                        </div>
                    </td>
                    <td>
                      <div className="text-left text-white font-light" style={{"textDecoration": "underline", "textDecorationThickness": "3px", "textUnderlineOffset": "4px"}}>
                      You can’t rush art
                      </div>
                      <div className="text-left text-white font-light text-3xl py-4">
                      Configure Your Modal
                      </div>
                      <div className="text-left text-white font-light howitworks-desktop-text">
                      Don’t worry, we focus on the little things too. With a variety of sizes, layouts, sidings, and finishes to choose from you can create the Modal that meets your exact needs. Get an individualized quote based on your selections.
                      </div>

                    </td>

                    <td rowSpan="3" className="howitworks-side-big-arrows-bounce items-center text-center" style={{"verticalAlign":"middle", "width":"30px"}} onClick={ sliderRef?.slickNext }>
                        <div className="text-gray-400 font-light font-maxeville" onClick={()=> {selectAreaOnTimeline("1next");}} >
                            <img src="https://api.checksolarcost.com/v1/getImageFile/desktop-navigation-right-big.png" width="35px"/> 
                        </div>
                    </td>
                  </tr>
                </tbody>

                <td colspan="4" className="howitworks-side-small-arrows">

                  <div className="text-left text-white font-light">
                  Don’t worry, we focus on the little things too. With a variety of sizes, layouts, sidings, and finishes to choose from you can create the Modal that meets your exact needs. Get an individualized quote based on your selections.
                  </div>

                  <table>
                    <tbody>
                        <td style={{"width":"50%"}}>
                          <div className="text-gray-400 font-light font-maxeville" onClick={ sliderRef?.slickPrev }>
                            <div onClick={()=> {selectAreaOnTimeline("1prev");}}>
                              <img src="https://api.checksolarcost.com/v1/getImageFile/mobile-navigation-left-new.png" width="60px" style={{"float":"right"}}/>  
                            </div>
                          </div>
                        </td>
                        <td style={{"width":"50%"}}>
                          <div className="text-gray-400 font-light font-maxeville" onClick={ sliderRef?.slickNext }>
                            <div onClick={()=> {selectAreaOnTimeline("1next");}}>
                              <img src="https://api.checksolarcost.com/v1/getImageFile/mobile-navigation-right-new.png" width="60px" style={{"float":"left"}}/>  
                            </div>
                          </div>
                        </td>
                      </tbody>
                  </table>
                </td>

                <tr>
                  <td colspan="4">
                    <div className="action-button">
                      <a href="/design-builder" target="blank">
                        <div className="transition transition-delay-longer text-white border border-white hover:text-white hover:bg-transparent mt-6 px-8 py-3 text-center  tracking-wide modal-button md:inline-block max-w-sm">
                         
                          <table style={{"margin":"0px"}}>
                            <tbody>
                              <tr>
                                <td>Design Your Modal</td>
                                <td><img src="https://api.checksolarcost.com/v1/getImageFile/mobile-navigation-right-new.png" width="15px"/></td>
                              </tr>
                            </tbody>
                          </table>

                        </div>
                      </a>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </section>

        <section >
          <div style={{
              "backgroundImage": "linear-gradient( rgba(79, 83, 81, 0.9), rgba(79, 83, 81, 0.9) ), url('https://api.checksolarcost.com/v1/getImageFile/how_it_works_slide2.jpg')", 
              "backgroundSize": "cover",
              "backgroundPosition": "center",
              "backgroundRepeat": "no-repeat",
              "width": '100vw',
              "height": '100%',
              "filter": "blur(20%)"
              }}>
            
              <div className="howitworks-main-table">
              <table>
                <tbody style={{"padding":"0px", "verticalAlign": "top"}}>
                  <tr>
                    <td rowSpan="2" className="howitworks-side-big-arrows" style={{"verticalAlign":"middle", "width":"30px"}} onClick={sliderRef?.slickPrev}>
                      <div className="text-gray-400 font-light font-maxeville" onClick={()=> {selectAreaOnTimeline("2prev");}}>
                          <img src="https://api.checksolarcost.com/v1/getImageFile/desktop-navigation-left-big.png" width="35px"/> 
                      </div>
                    </td>
                    <td className="text-right">
                        <div className="text-gray-400 font-maxeville text-center howitworks-number">
                       2
                        </div>
                    </td>
                    <td>
                      <div className="text-left text-white font-light" style={{"textDecoration": "underline", "textDecorationThickness": "3px", "textUnderlineOffset": "4px"}}>
                      3–5 Days
                      </div>
                      <div className="text-left text-white font-light text-3xl py-4">
                      Feasibility Study
                      </div>
                      <div className="text-left text-white font-light howitworks-desktop-text">
                      Our team will be with you every step of the way. After ordering your study, we'll review your design, answer any questions, and start a property assessment to make sure we’re a good fit. If not, don’t worry—we guarantee a full refund!
                      </div>
                    </td>
                    <td rowSpan="3" className="howitworks-side-big-arrows items-center text-center" style={{"verticalAlign":"middle", "width":"30px"}} onClick={ sliderRef?.slickNext }>
                        <div className="text-gray-400 font-light font-maxeville" onClick={()=> {selectAreaOnTimeline("2next");}}>
                            <img src="https://api.checksolarcost.com/v1/getImageFile/desktop-navigation-right-big.png" width="35px"/> 
                        </div>
                    </td>
                  </tr>
                </tbody>

                <td colspan="4" className="howitworks-side-small-arrows">
                  <div className="text-left text-white font-light">
                  Our team will be with you every step of the way. After ordering your study, we'll review your design, answer any questions, and start a property assessment to make sure we’re a good fit. If not, don’t worry—we guarantee a full refund!
                  </div>
                  <table>
                    <tbody>
                        <td style={{"width":"50%"}}>
                          <div className="text-gray-400 font-light font-maxeville" onClick={ sliderRef?.slickPrev }>
                            <div onClick={()=> {selectAreaOnTimeline("2prev");}}>
                              <img src="https://api.checksolarcost.com/v1/getImageFile/mobile-navigation-left-new.png" width="60px" style={{"float":"right"}}/>  
                            </div>
                          </div>
                        </td>
                        <td style={{"width":"50%"}}>
                          <div className="text-gray-400 font-light font-maxeville" onClick={ sliderRef?.slickNext }>
                            <div onClick={()=> {selectAreaOnTimeline("2next");}}>
                              <img src="https://api.checksolarcost.com/v1/getImageFile/mobile-navigation-right-new.png" width="60px" style={{"float":"left"}}/>  
                            </div>
                          </div>
                        </td>
                      </tbody>
                  </table>
                </td>

                <tr>
                  <td colspan="4">
                    <div className="action-button">
                      <a href="/get-started" target="blank">
                        <div className="transition transition-delay-longer text-white border border-white hover:text-white hover:bg-transparent mt-6 px-8 py-3 text-center tracking-wide modal-button md:inline-block max-w-sm">
                          
                          <table style={{"margin":"0px"}}>
                            <tbody>
                              <tr>
                                <td>Get Started</td>
                                <td><img src="https://api.checksolarcost.com/v1/getImageFile/mobile-navigation-right-new.png" width="15px"/></td>
                              </tr>
                            </tbody>
                          </table>

                        </div>
                      </a>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </section>

        <section >
          <div style={{
              "backgroundImage": "linear-gradient( rgba(79, 83, 81, 0.9), rgba(79, 83, 81, 0.9) ), url('https://api.checksolarcost.com/v1/getImageFile/how_it_works_slide3.jpg')", 
              "backgroundSize": "cover",
              "backgroundPosition": "center",
              "backgroundRepeat": "no-repeat",
              "width": '100vw',
              "height": '100%',
              "filter": "blur(20%)"
              }}>
              <div className="howitworks-main-table">
              <table>
                <tbody style={{"padding":"0px", "verticalAlign": "top"}}>
                  <tr>
                    <td rowSpan="2" className="howitworks-side-big-arrows" style={{"verticalAlign":"middle", "width":"30px"}} onClick={sliderRef?.slickPrev}>
                      <div className="text-gray-400 font-light font-maxeville" onClick={()=> {selectAreaOnTimeline("3prev");}}>
                          <img src="https://api.checksolarcost.com/v1/getImageFile/desktop-navigation-left-big.png" width="35px"/> 
                      </div>
                    </td>
                    <td className="text-right">
                        <div className="text-gray-400 font-maxeville text-center howitworks-number">
                       3
                        </div>
                    </td>
                    <td>
                      <div className="text-left text-white font-light" style={{"textDecoration": "underline", "textDecorationThickness": "3px", "textUnderlineOffset": "4px"}}>
                      1–3 Days
                      </div>
                      <div className="text-left text-white font-light text-3xl py-4">
                      Sign Contract
                      </div>
                      <div className="text-left text-white font-light howitworks-desktop-text">
                      After signing the contract and making your initial payment, you'll set everything in motion! We begin submitting necessary forms and preparing permits.
                      </div>

                    </td>
                    <td rowSpan="3" className="howitworks-side-big-arrows items-center text-center" style={{"verticalAlign":"middle","width":"30px"}} onClick={ sliderRef?.slickNext }>
                        <div className="text-gray-400 font-light font-maxeville" onClick={()=> {selectAreaOnTimeline("3next");}}>
                    
                            <img src="https://api.checksolarcost.com/v1/getImageFile/desktop-navigation-right-big.png" width="35px"/> 
                
                        </div>
                    </td>
                  </tr>
                 
                </tbody>

                <td colspan="4" className="howitworks-side-small-arrows">

                  <div className="text-left text-white font-light">
                  After signing the contract and making your initial payment, you'll set everything in motion! We begin submitting necessary forms and preparing permits.
                  </div>

                  <table>
                    <tbody>
                        <td style={{"width":"50%"}}>
                          <div className="text-gray-400 font-light font-maxeville" onClick={ sliderRef?.slickPrev }>
                            <div onClick={()=> {selectAreaOnTimeline("3prev");}}>
                              <img src="https://api.checksolarcost.com/v1/getImageFile/mobile-navigation-left-new.png" width="60px" style={{"float":"right"}}/>  
                            </div>
                          </div>
                        </td>
                        <td style={{"width":"50%"}}>
                          <div className="text-gray-400 font-light font-maxeville" onClick={ sliderRef?.slickNext }>
                            <div onClick={()=> {selectAreaOnTimeline("3next");}}>
                              <img src="https://api.checksolarcost.com/v1/getImageFile/mobile-navigation-right-new.png" width="60px" style={{"float":"left"}}/>  
                            </div>
                          </div>
                        </td>
                      </tbody>
                  </table>
                </td>


              </table>
            </div>
          </div>
        </section>

        <section >
          <div style={{
              "backgroundImage": "linear-gradient( rgba(79, 83, 81, 0.9), rgba(79, 83, 81, 0.9) ), url('https://api.checksolarcost.com/v1/getImageFile/how_it_works_slide4.jpg')", 
              "backgroundSize": "cover",
              "backgroundPosition": "center",
              "backgroundRepeat": "no-repeat",
              "width": '100vw',
              "height": '100%',
              "filter": "blur(20%)"
              }}>
           
              <div className="howitworks-main-table">
              <table>
                <tbody style={{"padding":"0px", "verticalAlign": "top"}}>
                  <tr>
                    <td rowSpan="2" className="howitworks-side-big-arrows" style={{"verticalAlign":"middle", "width":"30px"}} onClick={sliderRef?.slickPrev}>
                      <div className="text-gray-400 font-light font-maxeville" onClick={()=> {selectAreaOnTimeline("4prev");}}>
                      
                          <img src="https://api.checksolarcost.com/v1/getImageFile/desktop-navigation-left-big.png" width="35px"/> 
                      
                      </div>
                    </td>
                    <td className="text-right">
                        <div className="text-gray-400 font-maxeville text-center howitworks-number">
                       4
                        </div>
                    </td>
                    <td>
                      <div className="text-left text-white font-light" style={{"textDecoration": "underline", "textDecorationThickness": "3px", "textUnderlineOffset": "4px"}}>
                      30-45 Days
                      </div>
                      <div className="text-left text-white font-light text-3xl py-4">
                      Permitting
                      </div>
                      <div className="text-left text-white font-light howitworks-desktop-text">
                      We’ll take care of all the permits, paperwork, and procedures necessary to start your build. We work back and forth with the city to make sure your Modal meets all criteria.
                      </div>

                    </td>
                    <td rowSpan="3" className="howitworks-side-big-arrows items-center text-center" style={{"verticalAlign":"middle", "width":"30px"}} onClick={ sliderRef?.slickNext }>
                        <div className="text-gray-400 font-light font-maxeville" onClick={()=> {selectAreaOnTimeline("4next");}}>
                         
                            <img src="https://api.checksolarcost.com/v1/getImageFile/desktop-navigation-right-big.png" width="35px"/> 
                      
                        </div>
                    </td>
                  </tr>
                  
                </tbody>

                <td colspan="4" className="howitworks-side-small-arrows">

                  <div className="text-left text-white font-light">
                  We’ll take care of all the permits, paperwork, and procedures necessary to start your build. We work back and forth with the city to make sure your Modal meets all criteria.
                  </div>

                  <table>
                    <tbody>
                        <td style={{"width":"50%"}}>
                          <div className="text-gray-400 font-light font-maxeville" onClick={ sliderRef?.slickPrev }>
                            <div onClick={()=> {selectAreaOnTimeline("4prev");}}>
                              <img src="https://api.checksolarcost.com/v1/getImageFile/mobile-navigation-left-new.png" width="60px" style={{"float":"right"}}/>  
                            </div>
                          </div>
                        </td>
                        <td style={{"width":"50%"}}>
                          <div className="text-gray-400 font-light font-maxeville" onClick={ sliderRef?.slickNext }>
                            <div onClick={()=> {selectAreaOnTimeline("4next");}}>
                              <img src="https://api.checksolarcost.com/v1/getImageFile/mobile-navigation-right-new.png" width="60px" style={{"float":"left"}}/>  
                            </div>
                          </div>
                        </td>
                      </tbody>
                  </table>
                </td>

              </table>
            </div>
          </div>
        </section>

        <section >
          <div style={{
              "backgroundImage": "linear-gradient( rgba(79, 83, 81, 0.9), rgba(79, 83, 81, 0.9) ), url('https://api.checksolarcost.com/v1/getImageFile/how_it_works_slide5.jpg')", 
              "backgroundSize": "cover",
              "backgroundPosition": "center",
              "backgroundRepeat": "no-repeat",
              "width": '100vw',
              "height": '100%',
              "filter": "blur(20%)"
              }}>
          
              <div className="howitworks-main-table">
              <table>
                <tbody style={{"padding":"0px", "verticalAlign": "top"}}>
                  <tr>
                    <td rowSpan="2" className="howitworks-side-big-arrows" style={{"verticalAlign":"middle", "width":"30px"}} onClick={sliderRef?.slickPrev}>
                      <div className="text-gray-400 font-light font-maxeville" onClick={()=> {selectAreaOnTimeline("5prev");}}>
                 
                          <img src="https://api.checksolarcost.com/v1/getImageFile/desktop-navigation-left-big.png" width="35px"/> 
                     
                      </div>
                    </td>
                    <td className="text-right">
                        <div className="text-gray-400 font-maxeville text-center howitworks-number">
                       5
                        </div>
                    </td>
                    <td>
                      <div className="text-left text-white font-light" style={{"textDecoration": "underline", "textDecorationThickness": "3px", "textUnderlineOffset": "4px"}}>
                      6 Weeks
                      </div>
                      <div className="text-left text-white font-light text-3xl py-4">
                      Site Prep + Build
                      </div>
                      <div className="text-left text-white font-light howitworks-desktop-text">
                      While your Modal is being built off-site, our team will be constructing your foundation, as well as preparing infrastructure for water, power and sewer so you can enjoy all the comforts immediately after install.
                      </div>

                    </td>
                    <td rowSpan="3" className="howitworks-side-big-arrows items-center text-center" style={{"verticalAlign":"middle", "width":"30px"}} onClick={ sliderRef?.slickNext }>
                        <div className="text-gray-400 font-light font-maxeville" onClick={()=> {selectAreaOnTimeline("5next");}}>
                    
                            <img src="https://api.checksolarcost.com/v1/getImageFile/desktop-navigation-right-big.png" width="35px"/> 
                        
                        </div>
                    </td>
                  </tr>
          
                </tbody>

                <td colspan="4" className="howitworks-side-small-arrows">

                  <div className="text-left text-white font-light">
                  While your Modal is being built off-site, our team will be constructing your foundation, as well as preparing infrastructure for water, power and sewer so you can enjoy all the comforts immediately after install.
                  </div>

                  <table>
                    <tbody>
                        <td style={{"width":"50%"}}>
                          <div className="text-gray-400 font-light font-maxeville" onClick={ sliderRef?.slickPrev }>
                            <div onClick={()=> {selectAreaOnTimeline("5prev");}}>
                              <img src="https://api.checksolarcost.com/v1/getImageFile/mobile-navigation-left-new.png" width="60px" style={{"float":"right"}}/>  
                            </div>
                          </div>
                        </td>
                        <td style={{"width":"50%"}}>
                          <div className="text-gray-400 font-light font-maxeville" onClick={ sliderRef?.slickNext }>
                            <div onClick={()=> {selectAreaOnTimeline("5next");}}>
                              <img src="https://api.checksolarcost.com/v1/getImageFile/mobile-navigation-right-new.png" width="60px" style={{"float":"left"}}/>  
                            </div>
                          </div>
                        </td>
                      </tbody>
                  </table>
                </td>

              </table>
            </div>
          </div>
        </section>

        <section >
          <div style={{
              "backgroundImage": "linear-gradient( rgba(79, 83, 81, 0.9), rgba(79, 83, 81, 0.9) ), url('https://api.checksolarcost.com/v1/getImageFile/how_it_works_slide6.jpg')", 
              "backgroundSize": "cover",
              "backgroundPosition": "center",
              "backgroundRepeat": "no-repeat",
              "width": '100vw',
              "height": '100%',
              "filter": "blur(20%)"
              }}>
          
              <div className="howitworks-main-table">
              <table>
                <tbody style={{"padding":"0px", "verticalAlign": "top"}}>
                  <tr>
                    <td rowSpan="2" className="howitworks-side-big-arrows" style={{"verticalAlign":"middle", "width":"30px"}} onClick={sliderRef?.slickPrev}>
                      <div className="text-gray-400 font-light font-maxeville" onClick={()=> {selectAreaOnTimeline("6prev");}}>
                    
                          <img src="https://api.checksolarcost.com/v1/getImageFile/desktop-navigation-left-big.png" width="35px"/> 
                      
                      </div>
                    </td>
                    <td className="text-right">
                        <div className="text-gray-400 font-maxeville text-center howitworks-number">
                       6
                        </div>
                    </td>
                    <td>
                      <div className="text-left text-white font-light" style={{"textDecoration": "underline", "textDecorationThickness": "3px", "textUnderlineOffset": "4px"}}>
                      5 Days
                      </div>
                      <div className="text-left text-white font-light text-3xl py-4">
                      Delivery + Install
                      </div>
                      <div className="text-left text-white font-light howitworks-desktop-text">
                      Our team of professionals will guarantee a smooth, stress-free delivery with your Modal expertly handled from truck-to-installation. Once your Modal is carefully craned into place, we’ll have the utilities connected within hours.
                      </div>

                    </td>
                    <td rowSpan="3" className="howitworks-side-big-arrows items-center text-center" style={{"verticalAlign":"middle", "width":"30px"}} onClick={ sliderRef?.slickNext }>
                        <div className="text-gray-400 font-light font-maxeville" onClick={()=> {selectAreaOnTimeline("6next");}}>
                       
                            <img src="https://api.checksolarcost.com/v1/getImageFile/desktop-navigation-right-big.png" width="35px"/> 
                         
                        </div>
                    </td>
                  </tr>
                 
                </tbody>
                <td colspan="4" className="howitworks-side-small-arrows">

                  <div className="text-left text-white font-light">
                  Our team of professionals will guarantee a smooth, stress-free delivery with your Modal expertly handled from truck-to-installation. Once your Modal is carefully craned into place, we’ll have the utilities connected within hours.
                  </div>

                  <table>
                    <tbody>
                        <td style={{"width":"50%"}}>
                          <div className="text-gray-400 font-light font-maxeville" onClick={ sliderRef?.slickPrev }>
                            <div onClick={()=> {selectAreaOnTimeline("6prev");}}>
                              <img src="https://api.checksolarcost.com/v1/getImageFile/mobile-navigation-left-new.png" width="60px" style={{"float":"right"}}/>  
                            </div>
                          </div>
                        </td>
                        <td style={{"width":"50%"}}>
                          <div className="text-gray-400 font-light font-maxeville" onClick={ sliderRef?.slickNext }>
                            <div onClick={()=> {selectAreaOnTimeline("6next");}}>
                              <img src="https://api.checksolarcost.com/v1/getImageFile/mobile-navigation-right-new.png" width="60px" style={{"float":"left"}}/>  
                            </div>
                          </div>
                        </td>
                      </tbody>
                  </table>
                </td>

              </table>
            </div>
          </div>
        </section>

        <section >
          <div style={{
              "backgroundImage": "linear-gradient( rgba(79, 83, 81, 0.9), rgba(79, 83, 81, 0.9) ), url('https://api.checksolarcost.com/v1/getImageFile/how_it_works_slide7.jpg')", 
              "backgroundSize": "cover",
              "backgroundPosition": "center",
              "backgroundRepeat": "no-repeat",
              "width": '100vw',
              "height": '100%',
              "filter": "blur(20%)"
              }}>
         
              <div className="howitworks-main-table">
              <table>
                <tbody style={{"padding":"0px", "verticalAlign": "top"}}>
                  <tr>
                    <td rowSpan="2" className="howitworks-side-big-arrows" style={{"verticalAlign":"middle", "width": "30px"}} onClick={sliderRef?.slickPrev}>
                      <div className="text-gray-400 font-light font-maxeville" onClick={()=> {selectAreaOnTimeline("7prev");}}>
                     
                          <img src="https://api.checksolarcost.com/v1/getImageFile/desktop-navigation-left-big.png" width="35px"/>   
              
                      </div>
                    </td>
                    <td className="text-right">
                        <div className="text-gray-400 font-maxeville text-center howitworks-number">
                       7
                        </div>
                    </td>
                    <td>
                      <div className="text-left text-white font-light" style={{"textDecoration": "underline", "textDecorationThickness": "3px", "textUnderlineOffset": "4px"}}>
                      Worth the wait
                      </div>
                      <div className="text-left text-white font-light text-3xl py-4">
                      Move in
                      </div>
                      <div className="text-left text-white font-light howitworks-desktop-text">
                      Now it’s yours! Move in or rent it out, make your Modal meet your vision.
                      </div>

                    </td>
                    <td rowSpan="3" className="howitworks-side-big-arrows items-center text-center" style={{"verticalAlign":"middle", "width":"30px"}} onClick={ sliderRef?.slickNext }>
                        <div className="text-gray-400 font-light font-maxeville" onClick={()=> {selectAreaOnTimeline("7next");}}>
                            <img src="https://api.checksolarcost.com/v1/getImageFile/desktop-navigation-right-big.png" width="35px"/> 
                        </div>
                    </td>
                  </tr>
                </tbody>

                <td colspan="4" className="howitworks-side-small-arrows">

                  <div className="text-left text-white font-light">
                  Now it’s yours! Move in or rent it out, make your Modal meet your vision.
                  </div>

                  <table>
                    <tbody>
                        <td style={{"width":"50%"}}>
                          <div className="text-gray-400 font-light font-maxeville" onClick={ sliderRef?.slickPrev }>
                            <div onClick={()=> {selectAreaOnTimeline("7prev");}}>
                              <img src="https://api.checksolarcost.com/v1/getImageFile/mobile-navigation-left-new.png" width="60px" style={{"float":"right"}}/>  
                            </div>
                          </div>
                        </td>
                        <td style={{"width":"50%"}}>
                          <div className="text-gray-400 font-light font-maxeville" onClick={ sliderRef?.slickNext }>
                            <div onClick={()=> {selectAreaOnTimeline("7next");}}>
                              <img src="https://api.checksolarcost.com/v1/getImageFile/mobile-navigation-right-new.png" width="60px" style={{"float":"left"}}/>  
                            </div>
                          </div>
                        </td>
                      </tbody>
                  </table>
                </td>

                <tr>
                  <td colspan="4">
                    <div className="action-button">
                      <a href="/get-started" target="blank">
                        <div className="transition transition-delay-longer text-white border border-white hover:text-white hover:bg-transparent mt-6 px-8 py-3 text-center tracking-wide modal-button md:inline-block max-w-sm">
                          <table style={{"margin":"0px"}}>
                            <tbody>
                              <tr>
                                <td>Get Started</td>
                                <td><img src="https://api.checksolarcost.com/v1/getImageFile/mobile-navigation-right-new.png" width="15px"/></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </a>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </section>
      </Slider>


        {/*
        <div 
        dangerouslySetInnerHTML={{ __html: TimelineStep1 }} 
        style={styleObj}>
            </div> */}


      <div className="howitworks-timeline-area"
            id="timeline_area">
      </div> 


    </Layout>

    </div>

    </>
  )
}

