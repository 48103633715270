import React, { createRef, useState, useEffect } from "react"
import { Link } from "gatsby"

export default function TimelineTemplate1() {

  return (


  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 713.62 126.08"

  >
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #fff;\n      }\n\n      .cls-2 {\n        fill: #d19537;\n      }\n\n      .cls-3 {\n        fill: none;\n        stroke: #fff;\n        stroke-miterlimit: 10;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <g>
          <g>
            <path
              className="cls-1"
              d="m55.9,1.85c-.57-.4-1.25-.6-2.03-.6-.67,0-1.24.13-1.72.38-.48.25-.87.59-1.18,1.01-.31.42-.54.91-.68,1.45-.15.54-.22,1.11-.22,1.7,0,.64.07,1.24.22,1.81.15.57.37,1.07.68,1.49.31.42.7.76,1.19,1.01s1.06.38,1.73.38c.49,0,.93-.08,1.31-.24.38-.16.71-.39.99-.68.28-.29.5-.63.65-1.03s.25-.83.28-1.29h1.49c-.15,1.41-.63,2.51-1.46,3.3-.83.79-1.96,1.18-3.39,1.18-.87,0-1.63-.15-2.28-.45-.65-.3-1.19-.71-1.62-1.23-.43-.52-.75-1.14-.97-1.85-.21-.71-.32-1.48-.32-2.29s.12-1.58.35-2.3c.23-.72.57-1.34,1.01-1.88.45-.53,1-.96,1.67-1.26C52.26.16,53.02,0,53.88,0c.59,0,1.14.08,1.66.24.52.16.99.39,1.4.69s.75.68,1.02,1.14c.27.46.45.98.53,1.58h-1.49c-.17-.8-.54-1.39-1.11-1.79Z"
            />
            <path
              className="cls-1"
              d="m60.05,5.76c.17-.52.42-.97.75-1.35.33-.38.75-.68,1.24-.9s1.06-.33,1.7-.33,1.22.11,1.7.33c.49.22.9.52,1.23.9.33.38.59.83.75,1.35.17.52.25,1.08.25,1.67s-.08,1.15-.25,1.67c-.17.51-.42.96-.75,1.34-.33.38-.75.68-1.23.9-.49.21-1.05.32-1.7.32s-1.2-.11-1.7-.32c-.49-.21-.91-.51-1.24-.9-.33-.38-.59-.83-.75-1.34-.17-.51-.25-1.07-.25-1.67s.08-1.15.25-1.67Zm1.36,2.98c.13.38.31.7.54.96s.5.45.81.59.64.2.98.2.67-.07.98-.2.58-.33.81-.59.41-.58.54-.96.2-.82.2-1.31-.07-.93-.2-1.31c-.13-.38-.31-.7-.54-.97s-.5-.46-.81-.6c-.31-.14-.64-.2-.98-.2s-.67.07-.98.2c-.31.14-.58.33-.81.6s-.41.58-.54.97c-.13.38-.2.82-.2,1.31s.07.93.2,1.31Z"
            />
            <path
              className="cls-1"
              d="m70.51,3.36v1.29h.03c.55-.98,1.43-1.48,2.64-1.48.53,0,.98.07,1.33.22.36.15.64.35.86.61.22.26.37.57.46.93s.13.76.13,1.2v5.34h-1.33v-5.5c0-.5-.15-.9-.44-1.19-.29-.29-.7-.44-1.21-.44-.41,0-.76.06-1.06.19-.3.13-.55.3-.75.53-.2.23-.35.5-.45.81-.1.31-.15.65-.15,1.01v4.59h-1.33V3.36h1.26Z"
            />
            <path
              className="cls-1"
              d="m77.17,4.54v-1.18h1.37v-1.21c0-.66.19-1.16.57-1.5.38-.34.94-.51,1.67-.51.13,0,.27.01.43.03.16.02.31.05.43.09v1.16c-.12-.04-.24-.07-.38-.09s-.26-.02-.38-.02c-.32,0-.58.06-.75.19-.18.13-.27.37-.27.72v1.13h1.57v1.18h-1.57v6.94h-1.33v-6.94h-1.37Z"
            />
            <path
              className="cls-1"
              d="m82.72,1.9V.27h1.33v1.63h-1.33Zm1.33,1.46v8.12h-1.33V3.36h1.33Z"
            />
            <path
              className="cls-1"
              d="m92.22,13.77c-.61.66-1.56.99-2.86.99-.38,0-.76-.04-1.15-.13-.39-.08-.75-.22-1.07-.41s-.58-.43-.79-.74c-.21-.3-.32-.68-.34-1.12h1.33c.01.24.09.44.23.61.14.17.31.3.52.41.2.1.43.18.67.23.24.05.47.07.69.07.44,0,.81-.08,1.12-.23s.55-.36.75-.63c.2-.27.34-.59.43-.97s.13-.79.13-1.24v-.53h-.03c-.23.5-.58.87-1.04,1.11-.47.24-.96.35-1.48.35-.61,0-1.14-.11-1.59-.33-.45-.22-.83-.52-1.13-.89-.3-.37-.53-.81-.68-1.3-.15-.5-.23-1.02-.23-1.58,0-.48.06-.98.19-1.48s.33-.97.63-1.38c.29-.41.68-.75,1.16-1.02s1.07-.4,1.77-.4c.51,0,.98.11,1.41.34.43.23.76.56,1.01,1.01h.02v-1.16h1.26v7.43c0,1.33-.3,2.32-.91,2.98Zm-1.7-3.65c.31-.18.56-.42.75-.72.19-.3.33-.64.42-1.01.09-.38.13-.75.13-1.13,0-.36-.04-.71-.12-1.05-.08-.35-.22-.66-.4-.93-.18-.28-.42-.5-.71-.67-.29-.17-.65-.25-1.07-.25s-.79.08-1.1.24c-.3.16-.55.38-.75.65-.19.27-.33.59-.42.94s-.13.73-.13,1.11c0,.37.04.73.11,1.1.07.37.2.7.38,1,.18.3.41.54.71.72s.66.27,1.1.27.81-.09,1.12-.27Z"
            />
            <path
              className="cls-1"
              d="m100.6,11.48v-1.29h-.03c-.28.5-.64.87-1.08,1.11-.44.24-.96.35-1.55.35-.53,0-.98-.07-1.33-.21-.36-.14-.64-.34-.86-.6-.22-.26-.37-.57-.46-.93s-.13-.76-.13-1.2V3.36h1.33v5.5c0,.5.15.9.44,1.19.29.29.7.44,1.21.44.41,0,.76-.06,1.06-.19.3-.13.55-.3.75-.53.2-.23.35-.5.45-.81.1-.31.15-.65.15-1.01V3.36h1.33v8.12h-1.26Z"
            />
            <path
              className="cls-1"
              d="m105.08,3.36v1.71h.03c.32-.66.72-1.15,1.19-1.46.47-.31,1.07-.46,1.79-.44v1.41c-.53,0-.99.07-1.37.22s-.68.36-.91.64-.4.63-.5,1.03c-.11.4-.16.87-.16,1.39v3.61h-1.33V3.36h1.26Z"
            />
            <path
              className="cls-1"
              d="m114.56,10.96c-.61.46-1.37.69-2.29.69-.65,0-1.21-.1-1.69-.31-.48-.21-.88-.5-1.2-.88-.32-.38-.57-.83-.73-1.35s-.25-1.09-.27-1.71c0-.62.09-1.18.28-1.7s.45-.96.79-1.33c.34-.38.74-.67,1.21-.88.46-.21.98-.31,1.53-.31.72,0,1.32.15,1.8.45.48.3.86.68,1.15,1.14.29.46.49.96.6,1.51.11.54.15,1.06.13,1.55h-6.08c-.01.36.03.69.13,1.01.09.32.25.6.46.85s.48.44.8.59.71.22,1.15.22c.57,0,1.03-.13,1.39-.39s.6-.66.71-1.19h1.32c-.18.91-.57,1.6-1.18,2.06Zm-.32-5.21c-.12-.28-.28-.53-.49-.73-.2-.2-.44-.37-.72-.49s-.58-.18-.92-.18-.66.06-.93.18-.52.29-.71.49c-.2.21-.36.45-.47.73-.12.28-.18.57-.2.89h4.66c-.02-.31-.09-.61-.21-.9Z"
            />
            <path
              className="cls-1"
              d="m48.08,30.32h-1.49v-4.59l-4.33-6.63h1.77l3.36,5.34,3.28-5.34h1.7l-4.29,6.63v4.59Z"
            />
            <path
              className="cls-1"
              d="m51.47,24.6c.17-.52.42-.97.75-1.35.33-.38.75-.68,1.24-.9s1.06-.33,1.7-.33,1.22.11,1.7.33c.49.22.9.52,1.23.9.33.38.59.83.75,1.35.17.52.25,1.08.25,1.67s-.08,1.15-.25,1.67c-.17.51-.42.96-.75,1.34-.33.38-.75.68-1.23.9-.49.21-1.05.32-1.7.32s-1.2-.11-1.7-.32c-.49-.21-.91-.51-1.24-.9-.33-.38-.59-.83-.75-1.34-.17-.51-.25-1.07-.25-1.67s.08-1.15.25-1.67Zm1.36,2.98c.13.38.31.7.54.96s.5.45.81.59.64.2.98.2.67-.07.98-.2.58-.33.81-.59.41-.58.54-.96.2-.82.2-1.31-.07-.93-.2-1.31c-.13-.38-.31-.7-.54-.97s-.5-.46-.81-.6c-.31-.14-.64-.2-.98-.2s-.67.07-.98.2c-.31.14-.58.33-.81.6s-.41.58-.54.97c-.13.38-.2.82-.2,1.31s.07.93.2,1.31Z"
            />
            <path
              className="cls-1"
              d="m66.14,30.32v-1.29h-.03c-.28.5-.64.87-1.08,1.11-.44.24-.96.35-1.55.35-.53,0-.98-.07-1.33-.21-.36-.14-.64-.34-.86-.6-.22-.26-.37-.57-.46-.93s-.13-.76-.13-1.2v-5.34h1.33v5.5c0,.5.15.9.44,1.19.29.29.7.44,1.21.44.41,0,.76-.06,1.06-.19.3-.13.55-.3.75-.53.2-.23.35-.5.45-.81.1-.31.15-.65.15-1.01v-4.59h1.33v8.12h-1.26Z"
            />
            <path
              className="cls-1"
              d="m70.61,22.21v1.71h.03c.32-.66.72-1.15,1.19-1.46.47-.31,1.07-.46,1.79-.44v1.41c-.53,0-.99.07-1.37.22s-.68.36-.91.64-.4.63-.5,1.03c-.11.4-.16.87-.16,1.39v3.61h-1.33v-8.12h1.26Z"
            />
            <path
              className="cls-1"
              d="m81.29,19.11l3.53,9.42,3.55-9.42h2.04v11.21h-1.41v-9.33h-.03l-3.5,9.33h-1.27l-3.5-9.33h-.03v9.33h-1.41v-11.21h2.04Z"
            />
            <path
              className="cls-1"
              d="m92.49,24.6c.17-.52.42-.97.75-1.35.33-.38.75-.68,1.24-.9s1.06-.33,1.7-.33,1.22.11,1.7.33c.49.22.9.52,1.23.9.33.38.59.83.75,1.35.17.52.25,1.08.25,1.67s-.08,1.15-.25,1.67c-.17.51-.42.96-.75,1.34-.33.38-.75.68-1.23.9-.49.21-1.05.32-1.7.32s-1.2-.11-1.7-.32c-.49-.21-.91-.51-1.24-.9-.33-.38-.59-.83-.75-1.34-.17-.51-.25-1.07-.25-1.67s.08-1.15.25-1.67Zm1.36,2.98c.13.38.31.7.54.96s.5.45.81.59.64.2.98.2.67-.07.98-.2.58-.33.81-.59.41-.58.54-.96.2-.82.2-1.31-.07-.93-.2-1.31c-.13-.38-.31-.7-.54-.97s-.5-.46-.81-.6c-.31-.14-.64-.2-.98-.2s-.67.07-.98.2c-.31.14-.58.33-.81.6s-.41.58-.54.97c-.13.38-.2.82-.2,1.31s.07.93.2,1.31Z"
            />
            <path
              className="cls-1"
              d="m107.61,30.32v-1.1h-.03c-.22.45-.57.77-1.04.97s-.99.3-1.55.3c-.63,0-1.17-.12-1.64-.35-.47-.23-.85-.54-1.16-.93-.31-.39-.54-.84-.7-1.35-.16-.51-.24-1.06-.24-1.63s.08-1.12.23-1.63c.15-.51.38-.96.69-1.34.31-.38.7-.69,1.16-.91.46-.23,1.01-.34,1.62-.34.21,0,.43.02.67.06.24.04.48.11.72.21s.47.23.68.39c.21.16.39.36.54.6h.03v-4.18h1.33v11.21h-1.33Zm-4.8-2.88c.1.36.25.68.46.97s.47.51.79.68c.32.17.69.25,1.12.25s.83-.09,1.15-.27c.31-.18.57-.41.77-.7.2-.29.34-.61.43-.98.09-.37.13-.74.13-1.12,0-.4-.05-.78-.14-1.15-.09-.37-.24-.7-.45-.98-.2-.28-.47-.51-.79-.68-.33-.17-.72-.26-1.18-.26s-.83.09-1.15.27c-.31.18-.57.41-.75.71s-.33.63-.41,1c-.08.38-.13.76-.13,1.16,0,.38.05.75.15,1.11Z"
            />
            <path
              className="cls-1"
              d="m118.2,30.29c-.23.14-.55.2-.96.2-.35,0-.62-.1-.82-.29-.2-.19-.31-.51-.31-.95-.37.44-.79.76-1.28.95-.49.19-1.01.29-1.58.29-.37,0-.71-.04-1.04-.13-.33-.08-.62-.21-.86-.39-.24-.18-.43-.41-.57-.7-.14-.29-.21-.64-.21-1.04,0-.46.08-.84.24-1.13s.36-.53.62-.71.55-.32.88-.42c.33-.09.67-.17,1.01-.24.37-.07.71-.13,1.04-.17.33-.04.62-.09.87-.16.25-.07.45-.17.6-.3s.22-.32.22-.57c0-.29-.05-.53-.17-.71-.11-.18-.25-.31-.42-.41s-.37-.16-.58-.19c-.21-.03-.43-.05-.64-.05-.57,0-1.04.11-1.41.32-.38.21-.58.62-.61,1.22h-1.33c.02-.5.12-.93.31-1.27.19-.35.44-.63.75-.84.31-.21.67-.37,1.08-.46.4-.09.83-.14,1.3-.14.37,0,.73.03,1.09.08s.69.16.98.32c.29.16.53.39.71.68.18.29.27.67.27,1.14v4.17c0,.31.02.54.05.69.04.15.16.22.37.22.12,0,.25-.02.41-.06v1.04Zm-2.17-4.16c-.17.12-.39.22-.66.27-.27.06-.56.1-.86.14-.3.04-.6.08-.9.13-.3.05-.58.12-.82.23-.24.1-.44.25-.59.45-.15.19-.23.46-.23.79,0,.22.04.4.13.55.09.15.2.27.35.37.14.09.31.16.49.2s.39.06.6.06c.44,0,.82-.06,1.13-.18s.57-.27.77-.45c.2-.18.35-.38.44-.59.09-.21.14-.41.14-.6v-1.36Z"
            />
            <path
              className="cls-1"
              d="m120.85,19.11v11.21h-1.33v-11.21h1.33Z"
            />
          </g>
          <g>
            <path
              className="cls-1"
              d="m237.91,18.39c-.5-.36-1.13-.54-1.91-.54-.31,0-.62.03-.93.09-.3.06-.57.17-.81.31-.24.15-.42.34-.57.59-.14.25-.21.55-.21.92,0,.35.1.63.31.84.2.21.48.39.82.53s.72.25,1.15.34c.43.09.87.19,1.31.29.44.1.88.23,1.31.37.43.14.81.33,1.15.57.34.24.61.54.82.91.2.37.31.83.31,1.38,0,.6-.13,1.11-.4,1.53-.27.42-.61.77-1.03,1.04s-.89.46-1.41.58c-.52.12-1.03.18-1.55.18-.63,0-1.22-.08-1.78-.24-.56-.16-1.05-.4-1.48-.71s-.76-.73-1-1.23c-.25-.5-.37-1.09-.37-1.77h1.41c0,.47.09.88.28,1.22.18.34.42.62.72.84.3.22.65.38,1.04.49.4.1.81.16,1.22.16.33,0,.67-.03,1.01-.09s.65-.17.92-.32.49-.36.66-.62c.17-.26.25-.6.25-1,0-.39-.1-.7-.31-.94-.2-.24-.48-.44-.82-.59-.34-.15-.73-.27-1.15-.37-.43-.09-.87-.19-1.31-.29-.45-.1-.88-.21-1.31-.35-.43-.13-.81-.3-1.15-.52-.34-.21-.61-.49-.82-.83-.2-.34-.31-.77-.31-1.28,0-.57.12-1.05.35-1.47.23-.41.54-.75.92-1.02.38-.27.82-.47,1.3-.6.49-.13.99-.2,1.5-.2.58,0,1.11.07,1.6.2.49.14.92.35,1.29.64.37.29.67.66.88,1.11.21.45.33.98.35,1.59h-1.41c-.08-.81-.38-1.39-.87-1.75Z"
            />
            <path
              className="cls-1"
              d="m242.33,18.49v-1.63h1.33v1.63h-1.33Zm1.33,1.46v8.12h-1.33v-8.12h1.33Z"
            />
            <path
              className="cls-1"
              d="m251.83,30.36c-.61.66-1.56.99-2.86.99-.38,0-.76-.04-1.15-.13-.39-.08-.75-.22-1.07-.41s-.58-.43-.79-.74c-.21-.3-.32-.68-.34-1.12h1.33c.01.24.09.44.23.61.14.17.31.3.52.41.2.1.43.18.67.23.24.05.47.07.69.07.44,0,.81-.08,1.12-.23s.55-.36.75-.63c.2-.27.34-.59.43-.97s.13-.79.13-1.24v-.53h-.03c-.23.5-.58.87-1.04,1.11-.47.24-.96.35-1.48.35-.61,0-1.14-.11-1.59-.33-.45-.22-.83-.52-1.13-.89-.3-.37-.53-.81-.68-1.3-.15-.5-.23-1.02-.23-1.58,0-.48.06-.98.19-1.48s.33-.97.63-1.38c.29-.41.68-.75,1.16-1.02s1.07-.4,1.77-.4c.51,0,.98.11,1.41.34.43.23.76.56,1.01,1.01h.02v-1.16h1.26v7.43c0,1.33-.3,2.32-.91,2.98Zm-1.7-3.65c.31-.18.56-.42.75-.72.19-.3.33-.64.42-1.01.09-.38.13-.75.13-1.13,0-.36-.04-.71-.12-1.05-.08-.35-.22-.66-.4-.93-.18-.28-.42-.5-.71-.67-.29-.17-.65-.25-1.07-.25s-.79.08-1.1.24c-.3.16-.55.38-.75.65-.19.27-.33.59-.42.94s-.13.73-.13,1.11c0,.37.04.73.11,1.1.07.37.2.7.38,1,.18.3.41.54.71.72s.66.27,1.1.27.81-.09,1.12-.27Z"
            />
            <path
              className="cls-1"
              d="m256.01,19.95v1.29h.03c.55-.98,1.43-1.48,2.64-1.48.53,0,.98.07,1.33.22.36.15.64.35.86.61.22.26.37.57.46.93s.13.76.13,1.2v5.34h-1.33v-5.5c0-.5-.15-.9-.44-1.19-.29-.29-.7-.44-1.21-.44-.41,0-.76.06-1.06.19-.3.13-.55.3-.75.53-.2.23-.35.5-.45.81-.1.31-.15.65-.15,1.01v4.59h-1.33v-8.12h1.26Z"
            />
            <path
              className="cls-1"
              d="m274.84,18.44c-.57-.4-1.25-.6-2.03-.6-.67,0-1.24.13-1.72.38-.48.25-.87.59-1.18,1.01-.31.42-.54.91-.68,1.45-.15.54-.22,1.11-.22,1.7,0,.64.07,1.24.22,1.81.15.57.37,1.07.68,1.49.31.42.7.76,1.19,1.01s1.06.38,1.73.38c.49,0,.93-.08,1.31-.24.38-.16.71-.39.99-.68.28-.29.5-.63.65-1.03s.25-.83.28-1.29h1.49c-.15,1.41-.63,2.51-1.46,3.3-.83.79-1.96,1.18-3.39,1.18-.87,0-1.63-.15-2.28-.45-.65-.3-1.19-.71-1.62-1.23-.43-.52-.75-1.14-.97-1.85-.21-.71-.32-1.48-.32-2.29s.12-1.58.35-2.3c.23-.72.57-1.34,1.01-1.88.45-.53,1-.96,1.67-1.26.67-.31,1.43-.46,2.29-.46.59,0,1.14.08,1.66.24.52.16.99.39,1.4.69s.75.68,1.02,1.14c.27.46.45.98.53,1.58h-1.49c-.17-.8-.54-1.39-1.11-1.79Z"
            />
            <path
              className="cls-1"
              d="m279,22.34c.17-.52.42-.97.75-1.35.33-.38.75-.68,1.24-.9s1.06-.33,1.7-.33,1.22.11,1.7.33c.49.22.9.52,1.23.9.33.38.59.83.75,1.35.17.52.25,1.08.25,1.67s-.08,1.15-.25,1.67c-.17.51-.42.96-.75,1.34-.33.38-.75.68-1.23.9-.49.21-1.05.32-1.7.32s-1.2-.11-1.7-.32c-.49-.21-.91-.51-1.24-.9-.33-.38-.59-.83-.75-1.34-.17-.51-.25-1.07-.25-1.67s.08-1.15.25-1.67Zm1.36,2.98c.13.38.31.7.54.96s.5.45.81.59.64.2.98.2.67-.07.98-.2.58-.33.81-.59.41-.58.54-.96.2-.82.2-1.31-.07-.93-.2-1.31c-.13-.38-.31-.7-.54-.97s-.5-.46-.81-.6c-.31-.14-.64-.2-.98-.2s-.67.07-.98.2c-.31.14-.58.33-.81.6s-.41.58-.54.97c-.13.38-.2.82-.2,1.31s.07.93.2,1.31Z"
            />
            <path
              className="cls-1"
              d="m289.46,19.95v1.29h.03c.55-.98,1.43-1.48,2.64-1.48.53,0,.98.07,1.33.22.36.15.64.35.86.61.22.26.37.57.46.93s.13.76.13,1.2v5.34h-1.33v-5.5c0-.5-.15-.9-.44-1.19-.29-.29-.7-.44-1.21-.44-.41,0-.76.06-1.06.19-.3.13-.55.3-.75.53-.2.23-.35.5-.45.81-.1.31-.15.65-.15,1.01v4.59h-1.33v-8.12h1.26Z"
            />
            <path
              className="cls-1"
              d="m300.4,19.95v1.18h-1.62v5.04c0,.16.01.28.04.38.03.09.08.17.15.22.07.05.17.09.31.1s.3.02.51.02h.61v1.18h-1.02c-.35,0-.64-.02-.89-.07-.25-.05-.45-.13-.6-.26-.15-.13-.26-.3-.34-.53-.07-.23-.11-.53-.11-.91v-5.17h-1.38v-1.18h1.38v-2.43h1.33v2.43h1.62Z"
            />
            <path
              className="cls-1"
              d="m303.09,19.95v1.71h.03c.32-.66.72-1.15,1.19-1.46.47-.31,1.07-.46,1.79-.44v1.41c-.53,0-.99.07-1.37.22s-.68.36-.91.64-.4.63-.5,1.03c-.11.4-.16.87-.16,1.39v3.61h-1.33v-8.12h1.26Z"
            />
            <path
              className="cls-1"
              d="m314.3,28.04c-.23.14-.55.2-.96.2-.35,0-.62-.1-.82-.29-.2-.19-.31-.51-.31-.95-.37.44-.79.76-1.28.95-.49.19-1.01.29-1.58.29-.37,0-.71-.04-1.04-.13-.33-.08-.62-.21-.86-.39-.24-.18-.43-.41-.57-.7-.14-.29-.21-.64-.21-1.04,0-.46.08-.84.24-1.13s.36-.53.62-.71.55-.32.88-.42c.33-.09.67-.17,1.01-.24.37-.07.71-.13,1.04-.17.33-.04.62-.09.87-.16.25-.07.45-.17.6-.3s.22-.32.22-.57c0-.29-.05-.53-.17-.71-.11-.18-.25-.31-.42-.41s-.37-.16-.58-.19c-.21-.03-.43-.05-.64-.05-.57,0-1.04.11-1.41.32-.38.21-.58.62-.61,1.22h-1.33c.02-.5.12-.93.31-1.27.19-.35.44-.63.75-.84.31-.21.67-.37,1.08-.46.4-.09.83-.14,1.3-.14.37,0,.73.03,1.09.08s.69.16.98.32c.29.16.53.39.71.68.18.29.27.67.27,1.14v4.17c0,.31.02.54.05.69.04.15.16.22.37.22.12,0,.25-.02.41-.06v1.04Zm-2.17-4.16c-.17.12-.39.22-.66.27-.27.06-.56.1-.86.14-.3.04-.6.08-.9.13-.3.05-.58.12-.82.23-.24.1-.44.25-.59.45-.15.19-.23.46-.23.79,0,.22.04.4.13.55.09.15.2.27.35.37.14.09.31.16.49.2s.39.06.6.06c.44,0,.82-.06,1.13-.18s.57-.27.77-.45c.2-.18.35-.38.44-.59.09-.21.14-.41.14-.6v-1.36Z"
            />
            <path
              className="cls-1"
              d="m320.39,21.36c-.33-.28-.79-.42-1.35-.42-.48,0-.88.09-1.21.27-.33.18-.58.41-.78.71-.19.29-.33.63-.42,1.01-.08.38-.13.78-.13,1.19,0,.38.04.74.13,1.1s.23.67.42.95c.19.28.43.5.74.67.3.17.66.25,1.08.25.66,0,1.18-.17,1.55-.52.37-.35.6-.83.68-1.46h1.37c-.15,1-.52,1.78-1.12,2.32-.6.54-1.42.82-2.46.82-.62,0-1.16-.1-1.64-.3s-.87-.48-1.19-.85c-.32-.37-.56-.8-.72-1.31s-.24-1.07-.24-1.67.08-1.17.24-1.7c.16-.53.4-.99.71-1.38s.72-.7,1.2-.93c.48-.23,1.04-.34,1.68-.34.46,0,.89.05,1.29.17.4.11.76.28,1.07.51.31.23.56.52.76.87.2.35.32.77.38,1.25h-1.38c-.1-.51-.32-.91-.66-1.19Z"
            />
            <path
              className="cls-1"
              d="m327.44,19.95v1.18h-1.62v5.04c0,.16.01.28.04.38.03.09.08.17.15.22.07.05.17.09.31.1s.3.02.51.02h.61v1.18h-1.02c-.35,0-.64-.02-.89-.07-.25-.05-.45-.13-.6-.26-.15-.13-.26-.3-.34-.53-.07-.23-.11-.53-.11-.91v-5.17h-1.38v-1.18h1.38v-2.43h1.33v2.43h1.62Z"
            />
          </g>
          <g>
            <path
              className="cls-1"
              d="m444.81,1.8c-.5-.36-1.13-.54-1.91-.54-.31,0-.62.03-.93.09-.3.06-.57.17-.81.31-.24.15-.42.34-.57.59-.14.25-.21.55-.21.92,0,.35.1.63.31.84.2.21.48.39.82.53s.72.25,1.15.34c.43.09.87.19,1.31.29.44.1.88.23,1.31.37.43.14.81.33,1.15.57.34.24.61.54.82.91.2.37.31.83.31,1.38,0,.6-.13,1.11-.4,1.53-.27.42-.61.77-1.03,1.04s-.89.46-1.41.58c-.52.12-1.03.18-1.55.18-.63,0-1.22-.08-1.78-.24-.56-.16-1.05-.4-1.48-.71s-.76-.73-1-1.23c-.25-.5-.37-1.09-.37-1.77h1.41c0,.47.09.88.28,1.22.18.34.42.62.72.84.3.22.65.38,1.04.49.4.1.81.16,1.22.16.33,0,.67-.03,1.01-.09s.65-.17.92-.32.49-.36.66-.62c.17-.26.25-.6.25-1,0-.39-.1-.7-.31-.94-.2-.24-.48-.44-.82-.59-.34-.15-.73-.27-1.15-.37-.43-.09-.87-.19-1.31-.29-.45-.1-.88-.21-1.31-.35-.43-.13-.81-.3-1.15-.52-.34-.21-.61-.49-.82-.83-.2-.34-.31-.77-.31-1.28,0-.57.12-1.05.35-1.47.23-.41.54-.75.92-1.02.38-.27.82-.47,1.3-.6.49-.13.99-.2,1.5-.2.58,0,1.11.07,1.6.2.49.14.92.35,1.29.64.37.29.67.66.88,1.11.21.45.33.98.35,1.59h-1.41c-.08-.81-.38-1.39-.87-1.75Z"
            />
            <path
              className="cls-1"
              d="m449.23,1.9V.27h1.33v1.63h-1.33Zm1.33,1.46v8.12h-1.33V3.36h1.33Z"
            />
            <path
              className="cls-1"
              d="m456.1,3.36v1.18h-1.62v5.04c0,.16.01.28.04.38.03.09.08.17.15.22.07.05.17.09.31.1s.3.02.51.02h.61v1.18h-1.02c-.35,0-.64-.02-.89-.07-.25-.05-.45-.13-.6-.26-.15-.13-.26-.3-.34-.53-.07-.23-.11-.53-.11-.91v-5.17h-1.38v-1.18h1.38V.93h1.33v2.43h1.62Z"
            />
            <path
              className="cls-1"
              d="m463.33,10.96c-.61.46-1.37.69-2.29.69-.65,0-1.21-.1-1.69-.31-.48-.21-.88-.5-1.2-.88-.32-.38-.57-.83-.73-1.35s-.25-1.09-.27-1.71c0-.62.09-1.18.28-1.7s.45-.96.79-1.33c.34-.38.74-.67,1.21-.88.46-.21.98-.31,1.53-.31.72,0,1.32.15,1.8.45.48.3.86.68,1.15,1.14.29.46.49.96.6,1.51.11.54.15,1.06.13,1.55h-6.08c-.01.36.03.69.13,1.01.09.32.25.6.46.85s.48.44.8.59.71.22,1.15.22c.57,0,1.03-.13,1.39-.39s.6-.66.71-1.19h1.32c-.18.91-.57,1.6-1.18,2.06Zm-.32-5.21c-.12-.28-.28-.53-.49-.73-.2-.2-.44-.37-.72-.49s-.58-.18-.92-.18-.66.06-.93.18-.52.29-.71.49c-.2.21-.36.45-.47.73-.12.28-.18.57-.2.89h4.66c-.02-.31-.09-.61-.21-.9Z"
            />
            <path
              className="cls-1"
              d="m475.51.27c1.13,0,1.99.29,2.57.87s.87,1.4.87,2.44-.29,1.86-.87,2.45c-.58.59-1.44.87-2.57.86h-3.42v4.59h-1.49V.27h4.92Zm-.5,5.37c.85.01,1.47-.16,1.86-.52s.59-.87.59-1.54-.2-1.18-.59-1.53c-.39-.35-1.01-.53-1.86-.53h-2.92v4.11h2.92Z"
            />
            <path
              className="cls-1"
              d="m481.76,3.36v1.71h.03c.32-.66.72-1.15,1.19-1.46.47-.31,1.07-.46,1.79-.44v1.41c-.53,0-.99.07-1.37.22s-.68.36-.91.64-.4.63-.5,1.03c-.11.4-.16.87-.16,1.39v3.61h-1.33V3.36h1.26Z"
            />
            <path
              className="cls-1"
              d="m491.25,10.96c-.61.46-1.37.69-2.29.69-.65,0-1.21-.1-1.69-.31-.48-.21-.88-.5-1.2-.88-.32-.38-.57-.83-.73-1.35s-.25-1.09-.27-1.71c0-.62.09-1.18.28-1.7s.45-.96.79-1.33c.34-.38.74-.67,1.21-.88.46-.21.98-.31,1.53-.31.72,0,1.32.15,1.8.45.48.3.86.68,1.15,1.14.29.46.49.96.6,1.51.11.54.15,1.06.13,1.55h-6.08c-.01.36.03.69.13,1.01.09.32.25.6.46.85s.48.44.8.59.71.22,1.15.22c.57,0,1.03-.13,1.39-.39s.6-.66.71-1.19h1.32c-.18.91-.57,1.6-1.18,2.06Zm-.32-5.21c-.12-.28-.28-.53-.49-.73-.2-.2-.44-.37-.72-.49s-.58-.18-.92-.18-.66.06-.93.18-.52.29-.71.49c-.2.21-.36.45-.47.73-.12.28-.18.57-.2.89h4.66c-.02-.31-.09-.61-.21-.9Z"
            />
            <path
              className="cls-1"
              d="m495.31,3.36v1.1h.03c.22-.45.57-.78,1.04-.98.47-.2.99-.31,1.55-.31.63,0,1.17.12,1.64.35.47.23.85.54,1.16.93s.54.85.7,1.36c.16.51.24,1.06.24,1.63s-.08,1.12-.23,1.63c-.15.51-.38.96-.69,1.34-.31.38-.7.68-1.16.9-.47.22-1.01.33-1.62.33-.2,0-.42-.02-.67-.06s-.49-.11-.73-.2c-.24-.09-.47-.22-.68-.38-.21-.16-.4-.36-.54-.6h-.03v4.18h-1.33V3.36h1.33Zm4.8,2.88c-.1-.36-.25-.68-.46-.97-.2-.28-.47-.51-.79-.68-.32-.17-.69-.25-1.12-.25-.45,0-.83.09-1.15.27-.31.18-.57.41-.77.7-.2.29-.34.62-.43.98-.09.37-.13.74-.13,1.11,0,.4.05.78.14,1.15.09.37.24.7.45.98s.47.51.79.68.72.26,1.18.26.85-.09,1.15-.27c.31-.18.56-.41.75-.71s.32-.63.41-1c.08-.38.13-.76.13-1.16,0-.38-.05-.75-.15-1.11Z"
            />
            <path
              className="cls-1"
              d="m515.27,6.97v1.07h-3.42v3.44h-1.07v-3.44h-3.42v-1.07h3.42v-3.44h1.07v3.44h3.42Z"
            />
            <path
              className="cls-1"
              d="m462.83,19.11c.24,0,.5,0,.78,0,.28,0,.55.02.82.05.27.03.53.07.76.12s.44.13.6.24c.37.22.68.52.93.91s.38.86.38,1.43c0,.6-.14,1.11-.43,1.55-.29.43-.7.76-1.23.97v.03c.69.15,1.22.46,1.59.94.37.48.55,1.07.55,1.76,0,.41-.07.81-.22,1.19-.15.39-.36.73-.65,1.03s-.64.54-1.07.72c-.42.18-.91.27-1.47.27h-5.42v-11.21h4.07Zm.41,4.82c.85,0,1.46-.15,1.83-.45.37-.3.56-.75.56-1.34,0-.4-.06-.71-.19-.94-.12-.23-.3-.41-.52-.53s-.47-.21-.76-.24c-.29-.04-.59-.05-.92-.05h-2.98v3.56h2.98Zm.75,5.14c.66,0,1.17-.18,1.55-.53.37-.36.56-.85.56-1.48,0-.37-.07-.67-.2-.91-.14-.24-.32-.43-.54-.57-.23-.14-.48-.24-.78-.3-.29-.06-.6-.09-.91-.09h-3.41v3.88h3.74Z"
            />
            <path
              className="cls-1"
              d="m474.77,30.32v-1.29h-.03c-.28.5-.64.87-1.08,1.11-.44.24-.96.35-1.55.35-.53,0-.98-.07-1.33-.21-.36-.14-.64-.34-.86-.6-.22-.26-.37-.57-.46-.93s-.13-.76-.13-1.2v-5.34h1.33v5.5c0,.5.15.9.44,1.19.29.29.7.44,1.21.44.41,0,.76-.06,1.06-.19.3-.13.55-.3.75-.53.2-.23.35-.5.45-.81.1-.31.15-.65.15-1.01v-4.59h1.33v8.12h-1.26Z"
            />
            <path
              className="cls-1"
              d="m478.11,20.74v-1.63h1.33v1.63h-1.33Zm1.33,1.46v8.12h-1.33v-8.12h1.33Z"
            />
            <path
              className="cls-1"
              d="m482.93,19.11v11.21h-1.33v-11.21h1.33Z"
            />
            <path
              className="cls-1"
              d="m490.93,30.32v-1.1h-.03c-.22.45-.57.77-1.04.97s-.99.3-1.55.3c-.63,0-1.17-.12-1.64-.35-.47-.23-.85-.54-1.16-.93-.31-.39-.54-.84-.7-1.35-.16-.51-.24-1.06-.24-1.63s.08-1.12.23-1.63c.15-.51.38-.96.69-1.34.31-.38.7-.69,1.16-.91.46-.23,1.01-.34,1.62-.34.21,0,.43.02.67.06.24.04.48.11.72.21s.47.23.68.39c.21.16.39.36.54.6h.03v-4.18h1.33v11.21h-1.33Zm-4.8-2.88c.1.36.25.68.46.97s.47.51.79.68c.32.17.69.25,1.12.25s.83-.09,1.15-.27c.31-.18.57-.41.77-.7.2-.29.34-.61.43-.98.09-.37.13-.74.13-1.12,0-.4-.05-.78-.14-1.15-.09-.37-.24-.7-.45-.98-.2-.28-.47-.51-.79-.68-.33-.17-.72-.26-1.18-.26s-.83.09-1.15.27c-.31.18-.57.41-.75.71s-.33.63-.41,1c-.08.38-.13.76-.13,1.16,0,.38.05.75.15,1.11Z"
            />
          </g>
          <g>
            <path
              className="cls-1"
              d="m657.88,18.06l3.53,9.42,3.55-9.42h2.04v11.21h-1.41v-9.33h-.03l-3.5,9.33h-1.27l-3.5-9.33h-.03v9.33h-1.41v-11.21h2.04Z"
            />
            <path
              className="cls-1"
              d="m669.07,23.55c.17-.52.42-.97.75-1.35.33-.38.75-.68,1.24-.9s1.06-.33,1.7-.33,1.22.11,1.7.33c.49.22.9.52,1.23.9.33.38.59.83.75,1.35.17.52.25,1.08.25,1.67s-.08,1.15-.25,1.67c-.17.51-.42.96-.75,1.34-.33.38-.75.68-1.23.9-.49.21-1.05.32-1.7.32s-1.2-.11-1.7-.32c-.49-.21-.91-.51-1.24-.9-.33-.38-.59-.83-.75-1.34-.17-.51-.25-1.07-.25-1.67s.08-1.15.25-1.67Zm1.36,2.98c.13.38.31.7.54.96s.5.45.81.59.64.2.98.2.67-.07.98-.2.58-.33.81-.59.41-.58.54-.96.2-.82.2-1.31-.07-.93-.2-1.31c-.13-.38-.31-.7-.54-.97s-.5-.46-.81-.6c-.31-.14-.64-.2-.98-.2s-.67.07-.98.2c-.31.14-.58.33-.81.6s-.41.58-.54.97c-.13.38-.2.82-.2,1.31s.07.93.2,1.31Z"
            />
            <path
              className="cls-1"
              d="m680.51,29.28l-3.02-8.12h1.49l2.28,6.77h.03l2.21-6.77h1.4l-2.97,8.12h-1.43Z"
            />
            <path
              className="cls-1"
              d="m691.88,28.76c-.61.46-1.37.69-2.29.69-.65,0-1.21-.1-1.69-.31-.48-.21-.88-.5-1.2-.88-.32-.38-.57-.83-.73-1.35s-.25-1.09-.27-1.71c0-.62.09-1.18.28-1.7s.45-.96.79-1.33c.34-.38.74-.67,1.21-.88.46-.21.98-.31,1.53-.31.72,0,1.32.15,1.8.45.48.3.86.68,1.15,1.14.29.46.49.96.6,1.51.11.54.15,1.06.13,1.55h-6.08c-.01.36.03.69.13,1.01.09.32.25.6.46.85s.48.44.8.59.71.22,1.15.22c.57,0,1.03-.13,1.39-.39s.6-.66.71-1.19h1.32c-.18.91-.57,1.6-1.18,2.06Zm-.32-5.21c-.12-.28-.28-.53-.49-.73-.2-.2-.44-.37-.72-.49s-.58-.18-.92-.18-.66.06-.93.18-.52.29-.71.49c-.2.21-.36.45-.47.73-.12.28-.18.57-.2.89h4.66c-.02-.31-.09-.61-.21-.9Z"
            />
            <path className="cls-1" d="m700.7,18.06v11.21h-1.49v-11.21h1.49Z" />
            <path
              className="cls-1"
              d="m704.25,21.16v1.29h.03c.55-.98,1.43-1.48,2.64-1.48.53,0,.98.07,1.33.22.36.15.64.35.86.61.22.26.37.57.46.93s.13.76.13,1.2v5.34h-1.33v-5.5c0-.5-.15-.9-.44-1.19-.29-.29-.7-.44-1.21-.44-.41,0-.76.06-1.06.19-.3.13-.55.3-.75.53-.2.23-.35.5-.45.81-.1.31-.15.65-.15,1.01v4.59h-1.33v-8.12h1.26Z"
            />
            <path
              className="cls-1"
              d="m713.62,27.53v1.74h-1.74v-1.74h1.74Zm-.52-1.13h-.71l-.39-4.88v-3.45h1.49v3.45l-.39,4.88Z"
            />
          </g>
          <g>
            <path
              className="cls-1"
              d="m154.48,92.77v1.26h-5.87v3.56h5.15v1.26h-5.15v5.14h-1.49v-11.21h7.37Z"
            />
            <path
              className="cls-1"
              d="m161.65,103.46c-.61.46-1.37.69-2.29.69-.65,0-1.21-.1-1.69-.31-.48-.21-.88-.5-1.2-.88-.32-.38-.57-.83-.73-1.35s-.25-1.09-.27-1.71c0-.62.09-1.18.28-1.7s.45-.96.79-1.33c.34-.38.74-.67,1.21-.88.46-.21.98-.31,1.53-.31.72,0,1.32.15,1.8.45.48.3.86.68,1.15,1.14.29.46.49.96.6,1.51.11.54.15,1.06.13,1.55h-6.08c-.01.36.03.69.13,1.01.09.32.25.6.46.85s.48.44.8.59.71.22,1.15.22c.57,0,1.03-.13,1.39-.39s.6-.66.71-1.19h1.32c-.18.91-.57,1.6-1.18,2.06Zm-.32-5.21c-.12-.28-.28-.53-.49-.73-.2-.2-.44-.37-.72-.49s-.58-.18-.92-.18-.66.06-.93.18-.52.29-.71.49c-.2.21-.36.45-.47.73-.12.28-.18.57-.2.89h4.66c-.02-.31-.09-.61-.21-.9Z"
            />
            <path
              className="cls-1"
              d="m171.53,103.95c-.23.14-.55.2-.96.2-.35,0-.62-.1-.82-.29-.2-.19-.31-.51-.31-.95-.37.44-.79.76-1.28.95-.49.19-1.01.29-1.58.29-.37,0-.71-.04-1.04-.13-.33-.08-.62-.21-.86-.39-.24-.18-.43-.41-.57-.7-.14-.29-.21-.64-.21-1.04,0-.46.08-.84.24-1.13s.36-.53.62-.71.55-.32.88-.42c.33-.09.67-.17,1.01-.24.37-.07.71-.13,1.04-.17.33-.04.62-.09.87-.16.25-.07.45-.17.6-.3s.22-.32.22-.57c0-.29-.05-.53-.17-.71-.11-.18-.25-.31-.42-.41s-.37-.16-.58-.19c-.21-.03-.43-.05-.64-.05-.57,0-1.04.11-1.41.32-.38.21-.58.62-.61,1.22h-1.33c.02-.5.12-.93.31-1.27.19-.35.44-.63.75-.84.31-.21.67-.37,1.08-.46.4-.09.83-.14,1.3-.14.37,0,.73.03,1.09.08s.69.16.98.32c.29.16.53.39.71.68.18.29.27.67.27,1.14v4.17c0,.31.02.54.05.69.04.15.16.22.37.22.12,0,.25-.02.41-.06v1.04Zm-2.17-4.16c-.17.12-.39.22-.66.27-.27.06-.56.1-.86.14-.3.04-.6.08-.9.13-.3.05-.58.12-.82.23-.24.1-.44.25-.59.45-.15.19-.23.46-.23.79,0,.22.04.4.13.55.09.15.2.27.35.37.14.09.31.16.49.2s.39.06.6.06c.44,0,.82-.06,1.13-.18s.57-.27.77-.45c.2-.18.35-.38.44-.59.09-.21.14-.41.14-.6v-1.36Z"
            />
            <path
              className="cls-1"
              d="m173.79,102.17c.13.2.29.37.5.49.2.12.43.21.69.26.26.05.52.08.79.08.21,0,.43-.02.66-.05.23-.03.44-.09.64-.17.19-.08.35-.21.48-.37.12-.16.19-.37.19-.62,0-.35-.13-.61-.39-.79-.26-.18-.59-.32-.98-.43s-.82-.21-1.28-.3-.89-.21-1.28-.38c-.39-.16-.72-.39-.98-.68-.26-.29-.39-.7-.39-1.23,0-.41.09-.76.28-1.05.18-.29.42-.53.71-.71.29-.18.61-.32.97-.41.36-.09.72-.13,1.08-.13.46,0,.88.04,1.27.12.39.08.73.21,1.03.41.3.19.54.46.71.79.18.33.28.74.31,1.23h-1.33c-.02-.26-.09-.48-.2-.65s-.26-.31-.44-.42c-.18-.1-.38-.18-.59-.23-.21-.05-.43-.07-.65-.07-.2,0-.4.02-.6.05-.2.03-.39.09-.56.17-.17.08-.3.18-.41.31-.1.13-.16.3-.16.51,0,.23.08.42.24.57.16.15.37.28.62.38.25.1.53.18.85.25.31.07.63.14.94.21.33.07.66.16.98.27s.6.24.85.42c.25.17.44.39.6.65.15.26.23.59.23.97,0,.49-.1.9-.31,1.22-.2.32-.47.59-.8.79-.33.2-.7.34-1.12.42-.41.08-.82.12-1.23.12-.45,0-.88-.05-1.29-.14-.41-.09-.77-.25-1.08-.46-.31-.21-.57-.5-.75-.85s-.29-.78-.31-1.28h1.33c.01.29.08.54.2.75Z"
            />
            <path
              className="cls-1"
              d="m180.7,94.4v-1.63h1.33v1.63h-1.33Zm1.33,1.46v8.12h-1.33v-8.12h1.33Z"
            />
            <path
              className="cls-1"
              d="m185.49,92.77v4.19h.03c.22-.45.57-.78,1.04-.98.47-.2.99-.31,1.55-.31.63,0,1.17.12,1.64.35.47.23.85.54,1.16.93s.54.85.7,1.36c.16.51.24,1.06.24,1.63s-.08,1.12-.23,1.63c-.15.51-.38.96-.69,1.34-.31.38-.7.68-1.16.9-.47.22-1.01.33-1.62.33-.2,0-.42-.02-.67-.06s-.49-.11-.73-.2c-.24-.09-.47-.22-.68-.38-.21-.16-.4-.36-.54-.6h-.03v1.08h-1.33v-11.21h1.33Zm4.8,5.98c-.1-.36-.25-.68-.46-.97-.2-.28-.47-.51-.79-.68-.32-.17-.69-.25-1.12-.25-.45,0-.83.09-1.15.27-.31.18-.57.41-.77.7-.2.29-.34.62-.43.98-.09.37-.13.74-.13,1.11,0,.4.05.78.14,1.15.09.37.24.7.45.98s.47.51.79.68.72.26,1.18.26.85-.09,1.15-.27c.31-.18.56-.41.75-.71s.32-.63.41-1c.08-.38.13-.76.13-1.16,0-.38-.05-.75-.15-1.11Z"
            />
            <path
              className="cls-1"
              d="m193.5,94.4v-1.63h1.33v1.63h-1.33Zm1.33,1.46v8.12h-1.33v-8.12h1.33Z"
            />
            <path
              className="cls-1"
              d="m198.32,92.77v11.21h-1.33v-11.21h1.33Z"
            />
            <path
              className="cls-1"
              d="m200.47,94.4v-1.63h1.33v1.63h-1.33Zm1.33,1.46v8.12h-1.33v-8.12h1.33Z"
            />
            <path
              className="cls-1"
              d="m207.35,95.86v1.18h-1.62v5.04c0,.16.01.28.04.38.03.09.08.17.15.22.07.05.17.09.31.1s.3.02.51.02h.61v1.18h-1.02c-.35,0-.64-.02-.89-.07-.25-.05-.45-.13-.6-.26-.15-.13-.26-.3-.34-.53-.07-.23-.11-.53-.11-.91v-5.17h-1.38v-1.18h1.38v-2.43h1.33v2.43h1.62Z"
            />
            <path
              className="cls-1"
              d="m212.02,105.07c-.16.4-.31.73-.46,1-.15.27-.32.49-.5.67-.18.17-.39.3-.61.38-.22.08-.48.12-.78.12-.16,0-.31-.01-.47-.03s-.31-.06-.46-.11v-1.23c.12.05.25.1.4.13.15.04.28.05.38.05.27,0,.5-.07.68-.2.18-.13.32-.32.42-.56l.55-1.37-3.22-8.07h1.51l2.37,6.64h.03l2.28-6.64h1.41l-3.53,9.2Z"
            />
            <path
              className="cls-1"
              d="m167.11,113.15c-.5-.36-1.13-.54-1.91-.54-.31,0-.62.03-.93.09-.3.06-.57.17-.81.31-.24.15-.42.34-.57.59-.14.25-.21.55-.21.92,0,.35.1.63.31.84.2.21.48.39.82.53s.72.25,1.15.34c.43.09.87.19,1.31.29.44.1.88.23,1.31.37.43.14.81.33,1.15.57.34.24.61.54.82.91.2.37.31.83.31,1.38,0,.6-.13,1.11-.4,1.53-.27.42-.61.77-1.03,1.04s-.89.46-1.41.58c-.52.12-1.03.18-1.55.18-.63,0-1.22-.08-1.78-.24-.56-.16-1.05-.4-1.48-.71s-.76-.73-1-1.23c-.25-.5-.37-1.09-.37-1.77h1.41c0,.47.09.88.28,1.22.18.34.42.62.72.84.3.22.65.38,1.04.49.4.1.81.16,1.22.16.33,0,.67-.03,1.01-.09s.65-.17.92-.32.49-.36.66-.62c.17-.26.25-.6.25-1,0-.39-.1-.7-.31-.94-.2-.24-.48-.44-.82-.59-.34-.15-.73-.27-1.15-.37-.43-.09-.87-.19-1.31-.29-.45-.1-.88-.21-1.31-.35-.43-.13-.81-.3-1.15-.52-.34-.21-.61-.49-.82-.83-.2-.34-.31-.77-.31-1.28,0-.57.12-1.05.35-1.47.23-.41.54-.75.92-1.02.38-.27.82-.47,1.3-.6.49-.13.99-.2,1.5-.2.58,0,1.11.07,1.6.2.49.14.92.35,1.29.64.37.29.67.66.88,1.11.21.45.33.98.35,1.59h-1.41c-.08-.81-.38-1.39-.87-1.75Z"
            />
            <path
              className="cls-1"
              d="m174.92,114.71v1.18h-1.62v5.04c0,.16.01.28.04.38.03.09.08.17.15.22.07.05.17.09.31.1s.3.02.51.02h.61v1.18h-1.02c-.35,0-.64-.02-.89-.07-.25-.05-.45-.13-.6-.26-.15-.13-.26-.3-.34-.53-.07-.23-.11-.53-.11-.91v-5.17h-1.38v-1.18h1.38v-2.43h1.33v2.43h1.62Z"
            />
            <path
              className="cls-1"
              d="m181.86,122.83v-1.29h-.03c-.28.5-.64.87-1.08,1.11-.44.24-.96.35-1.55.35-.53,0-.98-.07-1.33-.21-.36-.14-.64-.34-.86-.6-.22-.26-.37-.57-.46-.93s-.13-.76-.13-1.2v-5.34h1.33v5.5c0,.5.15.9.44,1.19.29.29.7.44,1.21.44.41,0,.76-.06,1.06-.19.3-.13.55-.3.75-.53.2-.23.35-.5.45-.81.1-.31.15-.65.15-1.01v-4.59h1.33v8.12h-1.26Z"
            />
            <path
              className="cls-1"
              d="m191.05,122.83v-1.1h-.03c-.22.45-.57.77-1.04.97s-.99.3-1.55.3c-.63,0-1.17-.12-1.64-.35-.47-.23-.85-.54-1.16-.93-.31-.39-.54-.84-.7-1.35-.16-.51-.24-1.06-.24-1.63s.08-1.12.23-1.63c.15-.51.38-.96.69-1.34.31-.38.7-.69,1.16-.91.46-.23,1.01-.34,1.62-.34.21,0,.43.02.67.06.24.04.48.11.72.21s.47.23.68.39c.21.16.39.36.54.6h.03v-4.18h1.33v11.21h-1.33Zm-4.8-2.88c.1.36.25.68.46.97s.47.51.79.68c.32.17.69.25,1.12.25s.83-.09,1.15-.27c.31-.18.57-.41.77-.7.2-.29.34-.61.43-.98.09-.37.13-.74.13-1.12,0-.4-.05-.78-.14-1.15-.09-.37-.24-.7-.45-.98-.2-.28-.47-.51-.79-.68-.33-.17-.72-.26-1.18-.26s-.83.09-1.15.27c-.31.18-.57.41-.75.71s-.33.63-.41,1c-.08.38-.13.76-.13,1.16,0,.38.05.75.15,1.11Z"
            />
            <path
              className="cls-1"
              d="m197.63,123.91c-.16.4-.31.73-.46,1-.15.27-.32.49-.5.67-.18.17-.39.3-.61.38-.22.08-.48.12-.78.12-.16,0-.31-.01-.47-.03s-.31-.06-.46-.11v-1.23c.12.05.25.1.4.13.15.04.28.05.38.05.27,0,.5-.07.68-.2.18-.13.32-.32.42-.56l.55-1.37-3.22-8.07h1.51l2.37,6.64h.03l2.28-6.64h1.41l-3.53,9.2Z"
            />
          </g>
          <g>
            <path
              className="cls-1"
              d="m348.42,92.77c1.13,0,1.99.29,2.57.87s.87,1.4.87,2.44-.29,1.86-.87,2.45c-.58.59-1.44.87-2.57.86h-3.42v4.59h-1.49v-11.21h4.92Zm-.5,5.37c.85.01,1.47-.16,1.86-.52s.59-.87.59-1.54-.2-1.18-.59-1.53c-.39-.35-1.01-.53-1.86-.53h-2.92v4.11h2.92Z"
            />
            <path
              className="cls-1"
              d="m359.21,103.46c-.61.46-1.37.69-2.29.69-.65,0-1.21-.1-1.69-.31-.48-.21-.88-.5-1.2-.88-.32-.38-.57-.83-.73-1.35s-.25-1.09-.27-1.71c0-.62.09-1.18.28-1.7s.45-.96.79-1.33c.34-.38.74-.67,1.21-.88.46-.21.98-.31,1.53-.31.72,0,1.32.15,1.8.45.48.3.86.68,1.15,1.14.29.46.49.96.6,1.51.11.54.15,1.06.13,1.55h-6.08c-.01.36.03.69.13,1.01.09.32.25.6.46.85s.48.44.8.59.71.22,1.15.22c.57,0,1.03-.13,1.39-.39s.6-.66.71-1.19h1.32c-.18.91-.57,1.6-1.18,2.06Zm-.32-5.21c-.12-.28-.28-.53-.49-.73-.2-.2-.44-.37-.72-.49s-.58-.18-.92-.18-.66.06-.93.18-.52.29-.71.49c-.2.21-.36.45-.47.73-.12.28-.18.57-.2.89h4.66c-.02-.31-.09-.61-.21-.9Z"
            />
            <path
              className="cls-1"
              d="m363.1,95.86v1.71h.03c.32-.66.72-1.15,1.19-1.46.47-.31,1.07-.46,1.79-.44v1.41c-.53,0-.99.07-1.37.22s-.68.36-.91.64-.4.63-.5,1.03c-.11.4-.16.87-.16,1.39v3.61h-1.33v-8.12h1.26Z"
            />
            <path
              className="cls-1"
              d="m368.38,95.86v1.19h.03c.61-.92,1.48-1.38,2.62-1.38.5,0,.96.1,1.37.31s.7.57.86,1.07c.27-.44.63-.78,1.08-1.02s.93-.36,1.47-.36c.41,0,.78.04,1.11.13.33.09.61.23.85.42.24.19.42.43.55.73.13.3.2.66.2,1.08v5.95h-1.33v-5.32c0-.25-.02-.49-.06-.71s-.12-.41-.24-.57c-.12-.16-.27-.29-.48-.38s-.47-.14-.79-.14c-.66,0-1.18.19-1.55.57s-.57.88-.57,1.51v5.06h-1.33v-5.32c0-.26-.02-.5-.07-.72-.05-.22-.13-.41-.24-.57-.12-.16-.27-.29-.46-.38-.19-.09-.44-.13-.75-.13-.39,0-.72.08-1,.24-.28.16-.5.35-.67.57-.17.22-.3.45-.38.68-.08.24-.12.43-.12.59v5.06h-1.33v-8.12h1.26Z"
            />
            <path
              className="cls-1"
              d="m380.6,94.4v-1.63h1.33v1.63h-1.33Zm1.33,1.46v8.12h-1.33v-8.12h1.33Z"
            />
            <path
              className="cls-1"
              d="m387.47,95.86v1.18h-1.62v5.04c0,.16.01.28.04.38.03.09.08.17.15.22.07.05.17.09.31.1s.3.02.51.02h.61v1.18h-1.02c-.35,0-.64-.02-.89-.07-.25-.05-.45-.13-.6-.26-.15-.13-.26-.3-.34-.53-.07-.23-.11-.53-.11-.91v-5.17h-1.38v-1.18h1.38v-2.43h1.33v2.43h1.62Z"
            />
            <path
              className="cls-1"
              d="m392.42,95.86v1.18h-1.62v5.04c0,.16.01.28.04.38.03.09.08.17.15.22.07.05.17.09.31.1s.3.02.51.02h.61v1.18h-1.02c-.35,0-.64-.02-.89-.07-.25-.05-.45-.13-.6-.26-.15-.13-.26-.3-.34-.53-.07-.23-.11-.53-.11-.91v-5.17h-1.38v-1.18h1.38v-2.43h1.33v2.43h1.62Z"
            />
            <path
              className="cls-1"
              d="m393.98,94.4v-1.63h1.33v1.63h-1.33Zm1.33,1.46v8.12h-1.33v-8.12h1.33Z"
            />
            <path
              className="cls-1"
              d="m398.64,95.86v1.29h.03c.55-.98,1.43-1.48,2.64-1.48.53,0,.98.07,1.33.22.36.15.64.35.86.61.22.26.37.57.46.93s.13.76.13,1.2v5.34h-1.33v-5.5c0-.5-.15-.9-.44-1.19-.29-.29-.7-.44-1.21-.44-.41,0-.76.06-1.06.19-.3.13-.55.3-.75.53-.2.23-.35.5-.45.81-.1.31-.15.65-.15,1.01v4.59h-1.33v-8.12h1.26Z"
            />
            <path
              className="cls-1"
              d="m412.21,106.28c-.61.66-1.56.99-2.86.99-.38,0-.76-.04-1.15-.13-.39-.08-.75-.22-1.07-.41s-.58-.43-.79-.74c-.21-.3-.32-.68-.34-1.12h1.33c.01.24.09.44.23.61.14.17.31.3.52.41.2.1.43.18.67.23.24.05.47.07.69.07.44,0,.81-.08,1.12-.23s.55-.36.75-.63c.2-.27.34-.59.43-.97s.13-.79.13-1.24v-.53h-.03c-.23.5-.58.87-1.04,1.11-.47.24-.96.35-1.48.35-.61,0-1.14-.11-1.59-.33-.45-.22-.83-.52-1.13-.89-.3-.37-.53-.81-.68-1.3-.15-.5-.23-1.02-.23-1.58,0-.48.06-.98.19-1.48s.33-.97.63-1.38c.29-.41.68-.75,1.16-1.02s1.07-.4,1.77-.4c.51,0,.98.11,1.41.34.43.23.76.56,1.01,1.01h.02v-1.16h1.26v7.43c0,1.33-.3,2.32-.91,2.98Zm-1.7-3.65c.31-.18.56-.42.75-.72.19-.3.33-.64.42-1.01.09-.38.13-.75.13-1.13,0-.36-.04-.71-.12-1.05-.08-.35-.22-.66-.4-.93-.18-.28-.42-.5-.71-.67-.29-.17-.65-.25-1.07-.25s-.79.08-1.1.24c-.3.16-.55.38-.75.65-.19.27-.33.59-.42.94s-.13.73-.13,1.11c0,.37.04.73.11,1.1.07.37.2.7.38,1,.18.3.41.54.71.72s.66.27,1.1.27.81-.09,1.12-.27Z"
            />
          </g>
          <g>
            <g>
              <line
                className="cls-3"
                x1={6.61}
                y1={60.36}
                x2={631.97}
                y2={60.36}
              />
              <path
                className="cls-1"
                d="m631.8,63.74c1.87,0,3.38-1.52,3.38-3.38s-1.52-3.38-3.38-3.38-3.38,1.52-3.38,3.38,1.52,3.38,3.38,3.38Z"
              />
            </g>
            <path
              className="cls-3"
              d="m669.22,52.57v12.66m20.24-12.66v21.61m10.79,0h-21.58v-21.61h21.58v21.61Zm6.11,0h-46.48v-27.65h46.48v27.65Z"
            />
            <circle className="cls-3" cx={3.55} cy={60.36} r={3.05} />
            <g>
              <line
                className="cls-3"
                x1={82.07}
                y1={60.36}
                x2={82.07}
                y2={38.59}
              />
              <circle className="cls-1" cx={82.07} cy={38.68} r={1.88} />
            </g>
            <g>
              <line
                className="cls-3"
                x1={279.49}
                y1={60.36}
                x2={279.49}
                y2={38.59}
              />
              <circle className="cls-1" cx={279.49} cy={38.68} r={1.88} />
            </g>
            <g>
              <line
                className="cls-3"
                x1={475.43}
                y1={60.36}
                x2={475.43}
                y2={38.59}
              />
              <circle className="cls-1" cx={475.43} cy={38.68} r={1.88} />
            </g>
            <g>
              <line
                className="cls-3"
                x1={180.78}
                y1={82.13}
                x2={180.78}
                y2={60.36}
              />
              <path
                className="cls-1"
                d="m182.66,82.03c0,1.04-.84,1.88-1.88,1.88s-1.88-.84-1.88-1.88.84-1.88,1.88-1.88,1.88.84,1.88,1.88Z"
              />
            </g>
            <g>
              <line
                className="cls-3"
                x1={378.2}
                y1={82.13}
                x2={378.2}
                y2={60.36}
              />
              <path
                className="cls-1"
                d="m380.08,82.03c0,1.04-.84,1.88-1.88,1.88s-1.88-.84-1.88-1.88.84-1.88,1.88-1.88,1.88.84,1.88,1.88Z"
              />
            </g>
            <g>
              <line
                className="cls-3"
                x1={573.25}
                y1={82.13}
                x2={573.25}
                y2={60.36}
              />
              <path
                className="cls-1"
                d="m575.13,82.03c0,1.04-.84,1.88-1.88,1.88s-1.88-.84-1.88-1.88.84-1.88,1.88-1.88,1.88.84,1.88,1.88Z"
              />
            </g>
          </g>
          <g>
            <path
              className="cls-1"
              d="m549.95,92.77c1.73,0,3.06.44,3.99,1.32s1.4,2.22,1.4,4.02c0,.94-.11,1.78-.31,2.5-.21.73-.53,1.34-.97,1.84-.44.5-1,.88-1.68,1.14s-1.49.39-2.42.39h-3.85v-11.21h3.85Zm.13,9.96c.17,0,.38-.01.63-.04.25-.03.52-.09.8-.19s.56-.24.84-.43.53-.44.75-.77c.22-.32.4-.73.54-1.22s.21-1.08.21-1.77-.07-1.27-.2-1.8c-.13-.53-.35-.98-.64-1.35-.3-.37-.68-.65-1.15-.85-.47-.19-1.05-.29-1.74-.29h-2.51v8.7h2.48Z"
            />
            <path
              className="cls-1"
              d="m562.69,103.46c-.61.46-1.37.69-2.29.69-.65,0-1.21-.1-1.69-.31-.48-.21-.88-.5-1.2-.88-.32-.38-.57-.83-.73-1.35s-.25-1.09-.27-1.71c0-.62.09-1.18.28-1.7s.45-.96.79-1.33c.34-.38.74-.67,1.21-.88.46-.21.98-.31,1.53-.31.72,0,1.32.15,1.8.45.48.3.86.68,1.15,1.14.29.46.49.96.6,1.51.11.54.15,1.06.13,1.55h-6.08c-.01.36.03.69.13,1.01.09.32.25.6.46.85s.48.44.8.59.71.22,1.15.22c.57,0,1.03-.13,1.39-.39s.6-.66.71-1.19h1.32c-.18.91-.57,1.6-1.18,2.06Zm-.32-5.21c-.12-.28-.28-.53-.49-.73-.2-.2-.44-.37-.72-.49s-.58-.18-.92-.18-.66.06-.93.18-.52.29-.71.49c-.2.21-.36.45-.47.73-.12.28-.18.57-.2.89h4.66c-.02-.31-.09-.61-.21-.9Z"
            />
            <path
              className="cls-1"
              d="m566.78,92.77v11.21h-1.33v-11.21h1.33Z"
            />
            <path
              className="cls-1"
              d="m568.94,94.4v-1.63h1.33v1.63h-1.33Zm1.33,1.46v8.12h-1.33v-8.12h1.33Z"
            />
            <path
              className="cls-1"
              d="m574.57,103.98l-3.02-8.12h1.49l2.28,6.77h.03l2.21-6.77h1.4l-2.97,8.12h-1.43Z"
            />
            <path
              className="cls-1"
              d="m585.94,103.46c-.61.46-1.37.69-2.29.69-.65,0-1.21-.1-1.69-.31-.48-.21-.88-.5-1.2-.88-.32-.38-.57-.83-.73-1.35s-.25-1.09-.27-1.71c0-.62.09-1.18.28-1.7s.45-.96.79-1.33c.34-.38.74-.67,1.21-.88.46-.21.98-.31,1.53-.31.72,0,1.32.15,1.8.45.48.3.86.68,1.15,1.14.29.46.49.96.6,1.51.11.54.15,1.06.13,1.55h-6.08c-.01.36.03.69.13,1.01.09.32.25.6.46.85s.48.44.8.59.71.22,1.15.22c.57,0,1.03-.13,1.39-.39s.6-.66.71-1.19h1.32c-.18.91-.57,1.6-1.18,2.06Zm-.32-5.21c-.12-.28-.28-.53-.49-.73-.2-.2-.44-.37-.72-.49s-.58-.18-.92-.18-.66.06-.93.18-.52.29-.71.49c-.2.21-.36.45-.47.73-.12.28-.18.57-.2.89h4.66c-.02-.31-.09-.61-.21-.9Z"
            />
            <path
              className="cls-1"
              d="m589.84,95.86v1.71h.03c.32-.66.72-1.15,1.19-1.46.47-.31,1.07-.46,1.79-.44v1.41c-.53,0-.99.07-1.37.22s-.68.36-.91.64-.4.63-.5,1.03c-.11.4-.16.87-.16,1.39v3.61h-1.33v-8.12h1.26Z"
            />
            <path
              className="cls-1"
              d="m605.89,99.48v1.07h-3.42v3.44h-1.07v-3.44h-3.42v-1.07h3.42v-3.44h1.07v3.44h3.42Z"
            />
            <path
              className="cls-1"
              d="m555.52,111.61v11.21h-1.49v-11.21h1.49Z"
            />
            <path
              className="cls-1"
              d="m559.07,114.71v1.29h.03c.55-.98,1.43-1.48,2.64-1.48.53,0,.98.07,1.33.22.36.15.64.35.86.61.22.26.37.57.46.93s.13.76.13,1.2v5.34h-1.33v-5.5c0-.5-.15-.9-.44-1.19-.29-.29-.7-.44-1.21-.44-.41,0-.76.06-1.06.19-.3.13-.55.3-.75.53-.2.23-.35.5-.45.81-.1.31-.15.65-.15,1.01v4.59h-1.33v-8.12h1.26Z"
            />
            <path
              className="cls-1"
              d="m567.57,121.01c.13.2.29.37.5.49.2.12.43.21.69.26.26.05.52.08.79.08.21,0,.43-.02.66-.05.23-.03.44-.09.64-.17.19-.08.35-.21.48-.37.12-.16.19-.37.19-.62,0-.35-.13-.61-.39-.79-.26-.18-.59-.32-.98-.43s-.82-.21-1.28-.3-.89-.21-1.28-.38c-.39-.16-.72-.39-.98-.68-.26-.29-.39-.7-.39-1.23,0-.41.09-.76.28-1.05.18-.29.42-.53.71-.71.29-.18.61-.32.97-.41.36-.09.72-.13,1.08-.13.46,0,.88.04,1.27.12.39.08.73.21,1.03.41.3.19.54.46.71.79.18.33.28.74.31,1.23h-1.33c-.02-.26-.09-.48-.2-.65s-.26-.31-.44-.42c-.18-.1-.38-.18-.59-.23-.21-.05-.43-.07-.65-.07-.2,0-.4.02-.6.05-.2.03-.39.09-.56.17-.17.08-.3.18-.41.31-.1.13-.16.3-.16.51,0,.23.08.42.24.57.16.15.37.28.62.38.25.1.53.18.85.25.31.07.63.14.94.21.33.07.66.16.98.27s.6.24.85.42c.25.17.44.39.6.65.15.26.23.59.23.97,0,.49-.1.9-.31,1.22-.2.32-.47.59-.8.79-.33.2-.7.34-1.12.42-.41.08-.82.12-1.23.12-.45,0-.88-.05-1.29-.14-.41-.09-.77-.25-1.08-.46-.31-.21-.57-.5-.75-.85s-.29-.78-.31-1.28h1.33c.01.29.08.54.2.75Z"
            />
            <path
              className="cls-1"
              d="m577.87,114.71v1.18h-1.62v5.04c0,.16.01.28.04.38.03.09.08.17.15.22.07.05.17.09.31.1s.3.02.51.02h.61v1.18h-1.02c-.35,0-.64-.02-.89-.07-.25-.05-.45-.13-.6-.26-.15-.13-.26-.3-.34-.53-.07-.23-.11-.53-.11-.91v-5.17h-1.38v-1.18h1.38v-2.43h1.33v2.43h1.62Z"
            />
            <path
              className="cls-1"
              d="m586.54,122.8c-.23.14-.55.2-.96.2-.35,0-.62-.1-.82-.29-.2-.19-.31-.51-.31-.95-.37.44-.79.76-1.28.95-.49.19-1.01.29-1.58.29-.37,0-.71-.04-1.04-.13-.33-.08-.62-.21-.86-.39-.24-.18-.43-.41-.57-.7-.14-.29-.21-.64-.21-1.04,0-.46.08-.84.24-1.13s.36-.53.62-.71.55-.32.88-.42c.33-.09.67-.17,1.01-.24.37-.07.71-.13,1.04-.17.33-.04.62-.09.87-.16.25-.07.45-.17.6-.3s.22-.32.22-.57c0-.29-.05-.53-.17-.71-.11-.18-.25-.31-.42-.41s-.37-.16-.58-.19c-.21-.03-.43-.05-.64-.05-.57,0-1.04.11-1.41.32-.38.21-.58.62-.61,1.22h-1.33c.02-.5.12-.93.31-1.27.19-.35.44-.63.75-.84.31-.21.67-.37,1.08-.46.4-.09.83-.14,1.3-.14.37,0,.73.03,1.09.08s.69.16.98.32c.29.16.53.39.71.68.18.29.27.67.27,1.14v4.17c0,.31.02.54.05.69.04.15.16.22.37.22.12,0,.25-.02.41-.06v1.04Zm-2.17-4.16c-.17.12-.39.22-.66.27-.27.06-.56.1-.86.14-.3.04-.6.08-.9.13-.3.05-.58.12-.82.23-.24.1-.44.25-.59.45-.15.19-.23.46-.23.79,0,.22.04.4.13.55.09.15.2.27.35.37.14.09.31.16.49.2s.39.06.6.06c.44,0,.82-.06,1.13-.18s.57-.27.77-.45c.2-.18.35-.38.44-.59.09-.21.14-.41.14-.6v-1.36Z"
            />
            <path
              className="cls-1"
              d="m589.19,111.61v11.21h-1.33v-11.21h1.33Z"
            />
            <path
              className="cls-1"
              d="m592.68,111.61v11.21h-1.33v-11.21h1.33Z"
            />
          </g>
        </g>
        <rect className="cls-2" x={37.12} y={56.79} width={90} height={6} />
      </g>
    </g>
  </svg>

   )
}